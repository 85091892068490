import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { billingService, configureBillingApiWithAuth } from '../../api/billingService';
import Loader from '../Loader';
import { ApiResponse } from '../../types/api';

interface Subscription {
  id: string;
  status: 'active' | 'canceled' | 'past_due' | 'trialing';
  plan: {
    id: string;
    name: string;
    amount: number;
    currency: string;
    interval: 'month' | 'year';
  };
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
}

interface Invoice {
  id: string;
  number: string;
  date: string;
  amount: number;
  currency: string;
  status: 'paid' | 'open' | 'draft' | 'void' | 'uncollectible';
  url: string;
}

const BillingDashboard: React.FC = () => {
  const { user, getToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<ApiResponse<Subscription> | null>(null);
  const [invoices, setInvoices] = useState<ApiResponse<Invoice[]>>({ success: true, data: [], status: 200, message: '' });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      // Configure API with Auth0 token
      configureBillingApiWithAuth(getToken);
      
      const fetchBillingData = async (): Promise<void> => {
        try {
          setIsLoading(true);
          
          // Fetch subscription info
          const subscriptionData = await billingService.getSubscription(user.sub);
          setSubscription(subscriptionData);
          
          // Fetch invoices
          const invoicesData = await billingService.getInvoices(user.sub, 5);
          setInvoices(invoicesData);
          
          setError(null);
        } catch (err: any) {
          console.error('Error fetching billing data:', err);
          setError('Failed to load billing data. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchBillingData();
    }
  }, [user, getToken]);

  if (isLoading) {
    return <Loader text="Loading billing information..." />;
  }

  if (error) {
    return (
      <div className="text-center p-8">
        <h2 className="text-xl font-bold text-red-600">Error</h2>
        <p className="mt-2">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Billing Dashboard</h1>
      
      {/* Subscription Section */}
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Current Subscription</h2>
        <p className="text-gray-600">Subscription data coming soon...</p>
        <button 
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          type="button"
        >
          Manage Subscription
        </button>
      </div>
      
      {/* Payment Methods Section */}
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Payment Methods</h2>
        <p className="text-gray-600">Payment method data coming soon...</p>
        <button 
          className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          type="button"
        >
          Add Payment Method
        </button>
      </div>
      
      {/* Invoices Section */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Recent Invoices</h2>
        <p className="text-gray-600">Invoice data coming soon...</p>
      </div>
    </div>
  );
};

export default BillingDashboard; 