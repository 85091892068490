import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { projectService } from '../API/apiServices';
import Loader from '../../../Components/Loader';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { Project } from '../../../types';

interface Message {
  id: string;
  text: string;
  sender: 'user' | 'ai';
  timestamp: Date;
}

const ProjectDetail: React.FC = () => {
    const { projectID } = useParams<{ projectID: string }>();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [projectData, setProjectData] = useState<Project | null>(null);
    const [input, setInput] = useState<string>('');
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Sample chat messages for demonstration (will be replaced with real backend integration)
    const [messages, setMessages] = useState<Message[]>([]);

    // Generate sample messages based on project template
    const getSampleMessages = (template: string): Message[] => {
        const now = new Date();
        const oneMinuteAgo = new Date(now.getTime() - 60000);
        const twoMinutesAgo = new Date(now.getTime() - 120000);
        
        // Different sample messages based on template
        switch (template) {
            case 'web':
                return [
                    {
                        id: '1',
                        text: 'How can I help with your web development project?',
                        sender: 'ai',
                        timestamp: twoMinutesAgo
                    },
                    {
                        id: '2',
                        text: 'I need help with creating a responsive layout',
                        sender: 'user',
                        timestamp: oneMinuteAgo
                    },
                    {
                        id: '3',
                        text: 'I can help you with that. Let\'s start by discussing your design requirements.',
                        sender: 'ai',
                        timestamp: now
                    }
                ];
            case 'data':
                return [
                    {
                        id: '1',
                        text: 'What kind of data analysis are you working on?',
                        sender: 'ai',
                        timestamp: twoMinutesAgo
                    },
                    {
                        id: '2',
                        text: 'I need to analyze customer behavior data',
                        sender: 'user',
                        timestamp: oneMinuteAgo
                    },
                    {
                        id: '3',
                        text: 'Great! I can help you identify patterns and generate insights from your customer data.',
                        sender: 'ai',
                        timestamp: now
                    }
                ];
            default:
                return [
                    {
                        id: '1',
                        text: 'Hello! How can I assist you with your project today?',
                        sender: 'ai',
                        timestamp: twoMinutesAgo
                    },
                    {
                        id: '2',
                        text: 'I\'m just getting started with this project',
                        sender: 'user',
                        timestamp: oneMinuteAgo
                    },
                    {
                        id: '3',
                        text: 'No problem! Let me know what you\'re trying to achieve and I\'ll help guide you.',
                        sender: 'ai',
                        timestamp: now
                    }
                ];
        }
    };

    // Fetch project data when component mounts or projectID changes
    useEffect(() => {
        if (projectID && user?.user?._id) {
            fetchProject();
        }
    }, [projectID, user]);

    const fetchProject = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await projectService.getById(projectID as string);

            if (response.success && response.project) {
                setProjectData(response.project);
                // Create some sample messages for demonstration
                const sampleMessages = getSampleMessages(response.project.template);
                setMessages(sampleMessages);
                setError(null);
            } else {
                setError('Failed to fetch project details');
            }
        } catch (error) {
            console.error('Error fetching project:', error);
            setError('An error occurred while fetching the project');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return (
            <div className="text-center p-6">
                <div className="text-red-500 text-xl mb-4">{error}</div>
                <button 
                    onClick={() => navigate('/dashboard')}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Back to Dashboard
                </button>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 max-w-6xl">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
                {projectData && (
                    <div className="p-6">
                        <h1 className="text-2xl font-bold mb-4">{projectData.name}</h1>
                        <p className="text-gray-600 mb-6">{projectData.description}</p>
                        
                        {/* Messages would be rendered here */}
                        <div className="bg-gray-50 p-4 rounded-lg mb-4">
                            {messages.map(msg => (
                                <div 
                                    key={msg.id} 
                                    className={`mb-3 ${msg.sender === 'user' ? 'text-right' : ''}`}
                                >
                                    <div 
                                        className={`inline-block p-3 rounded-lg ${
                                            msg.sender === 'user' 
                                                ? 'bg-blue-500 text-white' 
                                                : 'bg-gray-200 text-gray-800'
                                        }`}
                                    >
                                        {msg.text}
                                    </div>
                                    <div className="text-xs text-gray-500 mt-1">
                                        {msg.timestamp.toLocaleTimeString()}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        
                        {/* Input box would be here */}
                        <div className="flex items-center">
                            <input
                                ref={inputRef}
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type your message..."
                                className="flex-grow p-3 border rounded-l focus:outline-none focus:ring-2 focus:ring-blue-300"
                            />
                            <button
                                className="bg-blue-500 text-white p-3 rounded-r hover:bg-blue-600"
                                disabled={isTyping || !input.trim()}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectDetail; 