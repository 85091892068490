import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4 py-16">
      {/* Checkmark Icon */}
      <div className="mb-8">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.3333 42.6667L15.3333 32.6667L18.6667 29.3333L25.3333 36L45.3333 16L48.6667 19.3333L25.3333 42.6667Z" fill="#C5C9D0"/>
        </svg>
      </div>
      
      {/* Thank You Text */}
      <h1 className="text-4xl font-bold text-gray-900 mb-2">Thank You</h1>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Your Order is Processing</h2>
      
      {/* Description */}
      <p className="text-center text-gray-700 max-w-2xl mb-10">
        Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
      </p>
      
      {/* Home Page Button */}
      <Link 
        to="/" 
        className="inline-block px-8 py-3 border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors font-medium rounded-none"
      >
        Home Page
      </Link>
    </div>
  );
};

export default ThankYouPage; 