import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectService } from './API/apiServices';
import { useAuthContext } from '../../hooks/useAuthContext';
import Loader from '../../Components/Loader';

interface Project {
  _id: string;
  name: string;
  description: string;
  template: string;
  lastActive: Date | string;
  userId: string;
}

interface ProjectTemplate {
  id: string;
  name: string;
  icon: string;
  description: string;
}

interface ProjectData {
  name: string;
  description: string;
  template: string;
  userId: string;
}

const DashboardHome: React.FC = () => {
    const navigate = useNavigate();
    const { user, isInitialized } = useAuthContext();
    const [newProjectName, setNewProjectName] = useState<string>('');
    const [newProjectDescription, setNewProjectDescription] = useState<string>('');
    const [selectedTemplate, setSelectedTemplate] = useState<string>('custom');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);
    
    // Project template options
    const projectTemplates: ProjectTemplate[] = [
        { id: 'web', name: 'Web Development', icon: '🌐', description: 'Create a new website or improve an existing one' },
        { id: 'data', name: 'Data Analysis', icon: '📊', description: 'Analyze and visualize your data' },
        { id: 'content', name: 'Content Creation', icon: '✍️', description: 'Generate marketing copy, blog posts, or creative writing' },
        { id: 'code', name: 'Code Assistant', icon: '💻', description: 'Get help with programming and debugging' },
    ];

    // Fetch projects only when user is loaded
    useEffect(() => {
        if (user?.user?._id) {
            fetchProjects();
        }
    }, [user]);

    const fetchProjects = async (): Promise<void> => {
        try {
            setLoading(true);
            // Get user ID from the context
            const userId = user?.user?._id;
            
            if (!userId) {
                setError('User information not available. Please refresh or log in again.');
                setLoading(false);
                return;
            }
            
            const response = await projectService.getAll(userId);
            if (response.success) {
                setProjects(response.projects);
            } else {
                setError('Failed to fetch projects');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            setError('An error occurred while fetching projects');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateProject = async (): Promise<void> => {
        if (newProjectName.trim() === '') return;
        
        try {
            setLoading(true);
            const userId = user?.user?._id;
            
            if (!userId) {
                setError('User information not available. Please refresh or log in again.');
                setLoading(false);
                return;
            }
            
            const projectData: ProjectData = {
                name: newProjectName,
                description: newProjectDescription || `${newProjectName} project`,
                template: selectedTemplate,
                userId
            };
            
            const response = await projectService.create(projectData);
            
            if (response.success) {
                setShowModal(false);
                // Refresh the projects list
                fetchProjects();
                // Navigate to the new project
                navigate(`/dashboard/project/${response.project._id}`);
            } else {
                setError('Failed to create project');
            }
        } catch (error) {
            console.error('Error creating project:', error);
            setError('An error occurred while creating the project');
        } finally {
            setLoading(false);
        }
    };

    const formatLastActive = (date: Date | string): string => {
        const now = new Date();
        const lastActive = new Date(date);
        const diffInHours = Math.floor((now.getTime() - lastActive.getTime()) / (1000 * 60 * 60));
        
        if (diffInHours < 1) {
            return 'Just now';
        } else if (diffInHours < 24) {
            return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
        } else if (diffInHours < 48) {
            return 'Yesterday';
        } else {
            return `${Math.floor(diffInHours / 24)} days ago`;
        }
    };

    const openDeleteModal = (project: Project, e: React.MouseEvent): void => {
        e.stopPropagation(); // Prevent navigation when clicking delete
        setProjectToDelete(project);
        setShowDeleteModal(true);
    };

    const handleDeleteProject = async (): Promise<void> => {
        if (!projectToDelete) return;
        
        try {
            setLoading(true);
            const userId = user?.user?._id;
            
            if (!userId) {
                setError('User information not available. Please refresh or log in again.');
                setLoading(false);
                return;
            }
            
            const response = await projectService.delete(projectToDelete._id, userId);
            
            if (response.success) {
                // Remove project from state
                setProjects(projects.filter(project => project._id !== projectToDelete._id));
                setShowDeleteModal(false);
                setProjectToDelete(null);
            } else {
                setError('Failed to delete project');
            }
        } catch (error) {
            console.error('Error deleting project:', error);
            setError('An error occurred while deleting the project');
        } finally {
            setLoading(false);
        }
    };

    // Show loading while auth is initializing or user data is not yet available
    if (!isInitialized || !user || loading && projects.length === 0) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <Loader text="Loading your dashboard..." />
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 py-8 px-6">
            <div className="max-w-7xl mx-auto">
                {/* Welcome header */}
                <div className="mb-10">
                    <h1 className="text-3xl font-bold text-gray-800">
                        Welcome {user?.user?.firstName ? `${user.user.firstName}` : ''}!
                    </h1>
                    <p className="text-gray-600 mt-2">Continue working on your projects or start something new.</p>
                </div>

                {/* Error message if any */}
                {error && (
                    <div className="mb-6 bg-red-50 border border-red-200 text-red-800 px-4 py-3 rounded-md flex items-center justify-between">
                        <div>{error}</div>
                        <button 
                            className="text-red-600"
                            onClick={() => setError(null)}
                        >
                            &times;
                        </button>
                    </div>
                )}

                {/* Create new project button */}
                <div className="mb-10">
                    <button
                        onClick={() => {
                            setNewProjectName('');
                            setNewProjectDescription('');
                            setSelectedTemplate('custom');
                            setShowModal(true);
                        }}
                        className="px-5 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors duration-200 flex items-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 00-1 1v5H4a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2h-5V4a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                        Create New Project
                    </button>
                </div>

                {/* Recent projects section */}
                <div className="mb-12">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Projects</h2>
                    {projects.length === 0 ? (
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 text-center">
                            <p className="text-gray-500">You don't have any projects yet. Create your first project to get started!</p>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {projects.map(project => (
                                <div 
                                    key={project._id}
                                    className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200 cursor-pointer relative"
                                >
                                    <div 
                                        className="p-5"
                                        onClick={() => navigate(`/dashboard/project/${project._id}`)}
                                    >
                                        <h3 className="text-lg font-medium text-gray-800 mb-2">{project.name}</h3>
                                        <div className="text-sm text-gray-500 mb-2">
                                            {project.description}
                                        </div>
                                        <div className="flex items-center justify-between text-xs text-gray-500">
                                            <span>Last active: {formatLastActive(project.lastActive)}</span>
                                            <span>Template: {project.template}</span>
                                        </div>
                                    </div>
                                    <div className="px-5 py-3 border-t border-gray-100 flex justify-between">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/dashboard/project/edit/${project._id}`);
                                            }}
                                            className="text-gray-500 hover:text-black text-sm flex items-center"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                            </svg>
                                            Edit
                                        </button>
                                        <button
                                            onClick={(e) => openDeleteModal(project, e)}
                                            className="text-red-500 hover:text-red-700 text-sm flex items-center"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                            </svg>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                
                {/* Create Project Modal */}
                {showModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6 relative">
                            <button
                                onClick={() => setShowModal(false)}
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            
                            <h2 className="text-xl font-bold text-gray-800 mb-4">Create a New Project</h2>
                            
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Project Name
                                </label>
                                <input
                                    type="text"
                                    value={newProjectName}
                                    onChange={(e) => setNewProjectName(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Enter project name"
                                />
                            </div>
                            
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Description (optional)
                                </label>
                                <textarea
                                    value={newProjectDescription}
                                    onChange={(e) => setNewProjectDescription(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    placeholder="Brief description of your project"
                                    rows={3}
                                ></textarea>
                            </div>
                            
                            <div className="mb-6">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Project Template
                                </label>
                                <div className="grid grid-cols-2 gap-3 mt-2">
                                    {projectTemplates.map(template => (
                                        <div
                                            key={template.id}
                                            className={`border rounded-md p-3 cursor-pointer transition-colors duration-200 ${selectedTemplate === template.id ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-gray-300'}`}
                                            onClick={() => setSelectedTemplate(template.id)}
                                        >
                                            <div className="flex items-center mb-1">
                                                <span className="text-xl mr-2">{template.icon}</span>
                                                <h3 className="font-medium text-sm">{template.name}</h3>
                                            </div>
                                            <p className="text-xs text-gray-500">{template.description}</p>
                                        </div>
                                    ))}
                                    <div
                                        className={`border rounded-md p-3 cursor-pointer transition-colors duration-200 ${selectedTemplate === 'custom' ? 'border-blue-500 bg-blue-50' : 'border-gray-200 hover:border-gray-300'}`}
                                        onClick={() => setSelectedTemplate('custom')}
                                    >
                                        <div className="flex items-center mb-1">
                                            <span className="text-xl mr-2">🛠️</span>
                                            <h3 className="font-medium text-sm">Custom</h3>
                                        </div>
                                        <p className="text-xs text-gray-500">Start with a blank slate</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 text-gray-600 mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCreateProject}
                                    className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 disabled:bg-gray-400"
                                    disabled={!newProjectName.trim()}
                                >
                                    Create Project
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                
                {/* Delete Confirmation Modal */}
                {showDeleteModal && projectToDelete && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                        <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
                            <h2 className="text-xl font-bold text-gray-800 mb-4">Delete Project</h2>
                            <p className="text-gray-600 mb-6">
                                Are you sure you want to delete "{projectToDelete.name}"? This action cannot be undone.
                            </p>
                            
                            <div className="flex justify-end">
                                <button
                                    onClick={() => {
                                        setShowDeleteModal(false);
                                        setProjectToDelete(null);
                                    }}
                                    className="px-4 py-2 text-gray-600 mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleDeleteProject}
                                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                                >
                                    Delete Project
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardHome; 