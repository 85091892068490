// API endpoints configuration
interface ApiEndpoints {
  auth: string;
  users: string;
  projects: string;
  analytics: string;
  billing: string;
  usage: string;
  [key: string]: string; // Allow for future expansion
}

// Base routes for all API services
const baseUrl: string = import.meta.env.VITE_API_BASE_URL || "http://localhost:4000";

// Service-specific URLs
const authUrl: string = import.meta.env.VITE_AUTH_URL || "http://localhost:3001";
const userManagementUrl: string = import.meta.env.VITE_USER_MANAGEMENT_URL || "http://localhost:3000";
const analyticsUrl: string = import.meta.env.VITE_ANALYTICS_URL || "http://localhost:3000";
const billingUrl: string = import.meta.env.VITE_BILLING_URL || "http://localhost:3000";
const usageUrl: string = import.meta.env.VITE_USAGE_URL || "http://localhost:3000";
const projectsUrl: string = import.meta.env.VITE_PROJECTS_URL || "http://localhost:3000";

// Service-specific endpoints
const apiEndpoints: ApiEndpoints = {
  auth: `${authUrl}/api/auth`,
  users: `${userManagementUrl}/api/users`,
  projects: `${projectsUrl}/api/projects`,
  analytics: `${analyticsUrl}/api/analytics`,
  billing: `${billingUrl}/api/billing`,
  usage: `${usageUrl}/api/usage`
};

export default apiEndpoints; 