import React from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import HomeLayout from "../Pages/Home/HomeLayout";
import LandingPage from "../Pages/Home/LandingPage";
import DashboardLayout from "../Pages/Dashboard/DashboardLayout";
import DashboardHome from "../Pages/Dashboard/DashboardHome";
// Make sure to import the updated DashboardProjects component
import DashboardProjects from "../Pages/Dashboard/DashboardProjects"; 
import DashboardSettings from "../Pages/Dashboard/DashboardSettings";
import Login from "../Pages/Auth/Login";
import SignUp from "../Pages/Auth/SignUp";
import Callback from "../Pages/Auth/Callback";
import PricingPage from "../Pages/Home/PricingPage";
import ContactUs from "../Pages/Home/ContactUs";
import ErrorPage from "../Pages/Home/ErrorPage";
import ThankYouPage from "../Pages/Home/ThankYouPage";
import ProjectDetail from "../Pages/Dashboard/Component/ProjectDetail";
import ProjectEdit from "../Pages/Dashboard/Component/ProjectEdit";

// Import new dashboard components
import AnalyticsDashboard from "../Components/analytics/AnalyticsDashboard";
import BillingDashboard from "../Components/billing/BillingDashboard";
import UsageMetrics from "../Components/usage/UsageMetrics";

// Subscriptions placeholder component
const SubscriptionsPage: React.FC = () => (
  <div className="p-6">
    <h1 className="text-2xl font-bold mb-6">Subscriptions</h1>
    <p>Subscription management interface coming soon...</p>
  </div>
);

// Define your route objects
const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "pricing",
        element: <PricingPage />,
      },
      {
        path: "contactUs",
        element: <ContactUs />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      },
      {
        path: "thanks",
        element: <ThankYouPage />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <DashboardHome />,
      },
      {
        path: "project/:projectID",
        element: <ProjectDetail />,
      },
      {
        path: "project/edit/:projectID",
        element: <ProjectEdit />,
      },
      {
        path: "projects",
        element: <DashboardProjects />,
      },
      {
        path: "settings",
        element: <DashboardSettings />,
      },
      // New dashboard routes with actual components
      {
        path: "analytics",
        element: <AnalyticsDashboard />,
      },
      {
        path: "billing",
        element: <BillingDashboard />,
      },
      {
        path: "subscriptions",
        element: <SubscriptionsPage />,
      },
      {
        path: "usage",
        element: <UsageMetrics />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/signup",
    element: <SignUp />
  },
  {
    path: "/callback",
    element: <Callback />
  },
  // Catch-all route
  {
    path: "*",
    element: <ErrorPage />
  }
];

// Create the router instance
export const router = createBrowserRouter(routes); 