import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from 'axios';
import apiEndpoints from '../API_Endpoint';
import { useAuth0 } from '@auth0/auth0-react';
import { ApiResponse } from '../types/api';

// Create a base API configuration creator
export const createApiInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    }
  });

  return instance;
};

// Add Auth0 token to requests
export const configureAuthInterceptor = (apiInstance: AxiosInstance): AxiosInstance => {
  // This should be used within a component where useAuth0 is available
  const { getAccessTokenSilently } = useAuth0();

  apiInstance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getAccessTokenSilently();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for API request:', e);
    }
    return config;
  });

  return apiInstance;
};

// Error handling utility for API requests
export const handleApiError = <T>(error: AxiosError | any, service: string, operation: string): ApiResponse<T> => {
  console.error(`${service} service error during ${operation}:`, error);
  
  const errorResponse: ApiResponse<T> = {
    success: false,
    status: error.response?.status || 500,
    message: error.response?.data?.message || error.message || `Error in ${service} service during ${operation}`,
    data: null as unknown as T
  };
  
  return errorResponse;
}; 