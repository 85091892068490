import React from 'react';

const StatsSection: React.FC = () => {
  return (
    <section className="py-16 px-6 bg-white">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800 mb-8">
          Bibendum amet at molestie mattis.
        </h2>
        
        <p className="text-center text-gray-700 max-w-4xl mx-auto mb-12">
          Rhoncus morbi et augue nec, in id ullamcorper at sit. Condimentum sit nunc in eros scelerisque sed. Commodo in viverra nunc, ullamcorper ut. Non, amet, aliquet scelerisque nullam sagittis, pulvinar. Fermentum scelerisque sit consectetur hac mi. Mollis leo eleifend ultricies purus iaculis.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Happy Customer Stat */}
          <div className="border border-gray-300 bg-slate-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-2xl transition-all duration-300">
            <div className="w-12 h-12 mb-4 text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
              </svg>
            </div>
            <h3 className="text-2xl md:text-3xl font-bold mb-1 text-gray-800">250+</h3>
            <p className="text-gray-600">Happy customer</p>
          </div>
          
          {/* Completed Projects Stat */}
          <div className="border border-gray-300 bg-slate-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-2xl transition-all duration-300">
            <div className="w-12 h-12 mb-4 text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-7-2h2V7h-4v2h2z" />
              </svg>
            </div>
            <h3 className="text-2xl md:text-3xl font-bold mb-1 text-gray-800">600+</h3>
            <p className="text-gray-600">Completed projects</p>
          </div>
          
          {/* Available Resources Stat */}
          <div className="border border-gray-300 bg-slate-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-2xl transition-all duration-300">
            <div className="w-12 h-12 mb-4 text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2l-5.5 9h11L12 2zm0 3.84L13.93 9h-3.87L12 5.84zM17.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM3 21.5h8v-8H3v8zm2-6h4v4H5v-4z" />
              </svg>
            </div>
            <h3 className="text-2xl md:text-3xl font-bold mb-1 text-gray-800">1.8K+</h3>
            <p className="text-gray-600">Available Resources</p>
          </div>
          
          {/* Subscribers Stat */}
          <div className="border border-gray-300 bg-slate-50 rounded-lg p-6 flex flex-col items-center justify-center hover:shadow-2xl transition-all duration-300">
            <div className="w-12 h-12 mb-4 text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
              </svg>
            </div>
            <h3 className="text-2xl md:text-3xl font-bold mb-1 text-gray-800">11K+</h3>
            <p className="text-gray-600">Subscribers</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatsSection; 