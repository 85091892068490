import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Create usage API instance
const usageApi = createApiInstance(apiEndpoints.usage);

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Resource type for usage tracking
type ResourceType = 'api' | 'storage' | 'compute' | 'project';

// Time range for usage metrics
type TimeRange = 'day' | 'week' | 'month' | 'year';

// Usage data interface
export interface UsageData {
  apiCalls: number;
  storage: number;
  compute: number;
  projects: number;
  history: Array<{
    date: string;
    value: number;
    type: ResourceType;
  }>;
}

// Quota data interface
export interface QuotaData {
  apiCalls: {
    limit: number;
    used: number;
    remaining: number;
  };
  storage: {
    limit: number;
    used: number;
    remaining: number;
    unit: string;
  };
  compute: {
    limit: number;
    used: number;
    remaining: number;
    unit: string;
  };
  projects: {
    limit: number;
    count: number;
    remaining: number;
  };
}

// Usage tracking data interface
export interface UsageTrackingData {
  userId: string;
  projectId?: string;
  resourceType: ResourceType;
  quantity: number;
  timestamp?: string;
  metadata?: Record<string, any>;
}

// Configure auth interceptor in components
export const configureUsageApiWithAuth = (getToken: GetTokenFn): void => {
  usageApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for usage API request:', e);
    }
    return config;
  });
};

// Usage API services
export const usageService = {
  // Get user's usage metrics
  getUserUsage: async (userId: string, timeRange: TimeRange = 'month'): Promise<ApiResponse<UsageData>> => {
    try {
      const response = await usageApi.get<ApiResponse<UsageData>>(`/users/${userId}`, {
        params: { timeRange }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', 'get user usage');
    }
  },

  // Get project usage metrics
  getProjectUsage: async (projectId: string, timeRange: TimeRange = 'month'): Promise<ApiResponse<UsageData>> => {
    try {
      const response = await usageApi.get<ApiResponse<UsageData>>(`/projects/${projectId}`, {
        params: { timeRange }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', `get project usage for ${projectId}`);
    }
  },

  // Get user's quota information
  getQuota: async (userId: string): Promise<ApiResponse<QuotaData>> => {
    try {
      const response = await usageApi.get<ApiResponse<QuotaData>>(`/quota/${userId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', 'get quota');
    }
  },

  // Track resource usage
  trackUsage: async (usageData: UsageTrackingData): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      const response = await usageApi.post<ApiResponse<{ success: boolean }>>('/track', usageData);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', 'track usage');
    }
  },

  // Check if a specific operation is allowed under quota
  checkQuotaAllowance: async (
    userId: string, 
    operationType: ResourceType, 
    quantity: number = 1
  ): Promise<ApiResponse<{ allowed: boolean; remaining: number }>> => {
    try {
      const response = await usageApi.post<ApiResponse<{ allowed: boolean; remaining: number }>>('/check-allowance', {
        userId,
        operationType,
        quantity
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', 'check quota allowance');
    }
  },

  // Get usage history
  getUsageHistory: async (
    userId: string, 
    timeRange: TimeRange = 'month', 
    resourceType: ResourceType | null = null
  ): Promise<ApiResponse<Array<{ date: string; value: number; type: ResourceType }>>> => {
    try {
      const params: Record<string, string> = { timeRange };
      if (resourceType) params.resourceType = resourceType;
      
      const response = await usageApi.get<ApiResponse<Array<{ date: string; value: number; type: ResourceType }>>>(`/history/${userId}`, { params });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Usage', 'get usage history');
    }
  }
};

export default usageService; 