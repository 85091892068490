import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import Loader from '../../Components/Loader';

/**
 * Callback component that handles authentication redirects
 * 
 * This component is displayed during the authentication callback process,
 * showing a loading spinner while the auth state is being processed
 */
const Callback: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    // If we have an authenticated user, redirect to dashboard
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Loader text="Processing authentication..." fullScreen={true} />
    </div>
  );
};

export default Callback; 