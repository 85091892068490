import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse, AnalyticsData } from '../types/api';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

// Create analytics API instance
const analyticsApi = createApiInstance(apiEndpoints.analytics);

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Type for event data
interface EventData {
  eventType: string;
  userId: string;
  projectId?: string;
  data: Record<string, any>;
  timestamp?: string;
}

// Dashboard data interface
interface DashboardData {
  summary: {
    totalProjects: number;
    activeProjects: number;
    totalUsage: number;
    usageLimit: number;
  };
  recentActivity: Array<{
    id: string;
    type: string;
    description: string;
    timestamp: string;
  }>;
  usageMetrics: Array<{
    name: string;
    value: number;
    unit: string;
  }>;
}

// Project analytics interface
interface ProjectAnalytics {
  usage: {
    current: number;
    limit: number;
    unit: string;
  };
  metrics: Array<{
    name: string;
    data: Array<[string, number]>; // [timestamp, value]
  }>;
}

// Configure auth interceptor in components
export const configureAnalyticsApiWithAuth = (getToken: GetTokenFn): void => {
  analyticsApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for analytics API request:', e);
    }
    return config;
  });
};

// Analytics API services
export const analyticsService = {
  // Get user dashboard data
  getDashboardData: async (userId: string, timeRange: string = 'month'): Promise<ApiResponse<DashboardData>> => {
    try {
      const response = await analyticsApi.get<ApiResponse<DashboardData>>(`/dashboard/${userId}`, {
        params: { timeRange }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Analytics', 'get dashboard data');
    }
  },

  // Get project usage analytics
  getProjectAnalytics: async (projectId: string, timeRange: string = 'month'): Promise<ApiResponse<ProjectAnalytics>> => {
    try {
      const response = await analyticsApi.get<ApiResponse<ProjectAnalytics>>(`/projects/${projectId}`, {
        params: { timeRange }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Analytics', `get project analytics for ${projectId}`);
    }
  },

  // Track event
  trackEvent: async (eventData: EventData): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      const response = await analyticsApi.post<ApiResponse<{ success: boolean }>>('/events', eventData);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Analytics', 'track event');
    }
  },

  // Get user events
  getUserEvents: async (
    userId: string, 
    timeRange: string = 'month', 
    eventType: string | null = null
  ): Promise<ApiResponse<Array<EventData>>> => {
    try {
      const params: Record<string, string> = { timeRange };
      if (eventType) params.eventType = eventType;
      
      const response = await analyticsApi.get<ApiResponse<Array<EventData>>>(`/users/${userId}/events`, { params });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Analytics', `get user events for ${userId}`);
    }
  },

  // Get Tableau dashboard token
  getTableauToken: async (dashboardId: string): Promise<ApiResponse<{ token: string; url: string }>> => {
    try {
      const response = await analyticsApi.get<ApiResponse<{ token: string; url: string }>>(`/tableau/token/${dashboardId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Analytics', 'get Tableau token');
    }
  }
};

export default analyticsService; 