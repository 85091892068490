import React, { useState, FormEvent, ChangeEvent } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import GoogleLoginButton from '../../Components/auth/GoogleLoginButton';
import Loader from '../../Components/Loader';

// Form data interface
interface SignUpFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
}

// Form errors interface
interface FormErrors {
  name?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  acceptTerms?: string;
}

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false
  });
  
  const [errors, setErrors] = useState<FormErrors>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginWithRedirect } = useAuthContext();

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
      isValid = false;
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    // Terms validation
    if (!formData.acceptTerms) {
      newErrors.acceptTerms = 'You must accept the terms and conditions';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      // Use Auth0 signup
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
          login_hint: formData.email
        }
      });
      toast.success("Redirecting to sign up...");
    } catch (error) {
      console.error("Signup error:", error);
      toast.error("Sign up failed. Please try again.");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader text="Creating your account..." />;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left side - Company info */}
      <div className="w-full lg:w-5/12 bg-gray-200 flex items-center justify-center py-12 lg:py-0">
        <div className="w-48 h-48 md:w-64 md:h-64 p-4 bg-white flex items-center justify-center">
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <svg viewBox="0 0 100 100" className="w-4/5 h-4/5 text-white">
              <path
                fill="currentColor"
                d="M10 10 L90 10 L50 50 L10 10 Z M10 90 L90 90 L50 50 L10 90 Z M10 10 L10 90 L50 50 L10 10 Z M90 10 L90 90 L50 50 L90 10 Z"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Right side - Sign up form */}
      <div className="w-full lg:w-7/12 bg-white p-6 sm:p-8 flex items-center justify-center">
        <div className="w-full max-w-md px-4">
          <h1 className="text-3xl font-semibold mb-6 sm:mb-8 text-gray-800">Create your account</h1>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                className={`w-full p-2 border ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                } rounded bg-gray-50`}
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className={`w-full p-2 border ${
                  errors.email ? 'border-red-500' : 'border-gray-300'
                } rounded bg-gray-50`}
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Create a password"
                className={`w-full p-2 border ${
                  errors.password ? 'border-red-500' : 'border-gray-300'
                } rounded bg-gray-50`}
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password ? (
                <p className="text-red-500 text-xs mt-1">{errors.password}</p>
              ) : (
                <p className="text-xs text-gray-600 mt-1">
                  Must be at least 8 characters long with a mix of letters, numbers and symbols.
                </p>
              )}
            </div>

            <div className="mb-6">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                className={`w-full p-2 border ${
                  errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
                } rounded bg-gray-50`}
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>
              )}
            </div>

            <div className="mb-6">
              <div className="flex items-start">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  className={`h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 mt-1`}
                  checked={formData.acceptTerms}
                  onChange={handleChange}
                />
                <label htmlFor="acceptTerms" className="ml-2 block text-sm text-gray-700">
                  I accept the{' '}
                  <a href="/terms" className="text-blue-600 hover:underline">
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a href="/privacy" className="text-blue-600 hover:underline">
                    Privacy Policy
                  </a>
                </label>
              </div>
              {errors.acceptTerms && <p className="text-red-500 text-xs mt-1">{errors.acceptTerms}</p>}
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
            >
              Create Account
            </button>

            <div className="my-6 flex items-center">
              <div className="flex-grow h-px bg-gray-300"></div>
              <span className="mx-4 text-sm text-gray-500">OR</span>
              <div className="flex-grow h-px bg-gray-300"></div>
            </div>

            <div className="mb-6">
              <GoogleLoginButton label="Sign up with Google" />
            </div>

            <div className="text-center text-sm">
              <span className="text-gray-600">Already have an account?</span>{' '}
              <Link to="/login" className="text-blue-600 hover:underline">
                Log In
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp; 