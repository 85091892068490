import React from 'react';
import { Link } from 'react-router-dom';
import GPlayImg from "/GPlay.png";
import AStoreImg from "/AStore.png";

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-600 text-white">
            {/* Newsletter Subscription */}
            <div className="container mx-auto px-6 md:px-12 py-8 flex flex-col md:flex-row justify-end items-center gap-4">
                <div className="flex w-full md:w-auto">
                    <div className="relative w-full md:w-auto">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                            </svg>
                        </span>
                        <input
                            type="email"
                            className="w-full md:w-64 py-2 pl-10 pr-4 text-gray-700 bg-white border focus:outline-none"
                            placeholder="Enter your email to get the latest news..."
                        />
                    </div>
                    <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 whitespace-nowrap">
                        Subscribe
                    </button>
                </div>
            </div>

            <div className="border-t border-gray-500 mx-6 md:mx-12"></div>

            {/* Footer Links */}
            <div className="container mx-auto px-6 md:px-12 py-8">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Column One */}
                    <div>
                        <h3 className="font-semibold mb-4">Column One</h3>
                        <ul className="space-y-2">
                            <li><Link to="/" className="hover:text-blue-300">Twenty One</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Thirty Two</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Fourty Three</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Fifty Four</Link></li>
                        </ul>
                    </div>

                    {/* Column Two */}
                    <div>
                        <h3 className="font-semibold mb-4">Column Two</h3>
                        <ul className="space-y-2">
                            <li><Link to="/" className="hover:text-blue-300">Sixty Five</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Seventy Six</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Eighty Seven</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Ninety Eight</Link></li>
                        </ul>
                    </div>

                    {/* Column Three */}
                    <div>
                        <h3 className="font-semibold mb-4">Column Three</h3>
                        <ul className="space-y-2">
                            <li><Link to="/" className="hover:text-blue-300">One Two</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Three Four</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Five Six</Link></li>
                            <li><Link to="/" className="hover:text-blue-300">Seven Eight</Link></li>
                        </ul>
                    </div>

                    {/* Column Four - App Store Links & Social Media */}
                    <div>
                        <h3 className="font-semibold mb-4">Column Four</h3>
                        <div className="flex space-x-2 mb-6">
                            <a href="#" className="block">
                                <img src={GPlayImg} alt="App Store" className="h-10" />
                            </a>
                            <a href="#" className="block">
                                <img src={AStoreImg} alt="Google Play" className="h-10" />
                            </a>
                        </div>

                        <h3 className="font-semibold mb-4">Join Us</h3>
                        <div className="flex space-x-4">
                            <a href="#" className="text-white hover:text-blue-300">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg>
                            </a>
                            <a href="#" className="text-white hover:text-blue-300">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg>
                            </a>
                            <a href="#" className="text-white hover:text-blue-300">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                            </a>
                            <a href="#" className="text-white hover:text-blue-300">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg>
                            </a>
                            <a href="#" className="text-white hover:text-blue-300">
                                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"></path></svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* Copyright and Additional Links */}
            <div className="border-t border-gray-500 mx-6 md:mx-12"></div>
            <div className="container mx-auto px-6 md:px-12 py-4 flex flex-col md:flex-row justify-between items-center">
                <div className="text-sm">
                    CompanyName @ 202X. All rights reserved.
                </div>
                <div className="flex space-x-6 mt-4 md:mt-0">
                    <Link to="/" className="text-sm hover:text-blue-300">Eleven</Link>
                    <Link to="/" className="text-sm hover:text-blue-300">Twelve</Link>
                    <Link to="/" className="text-sm hover:text-blue-300">Thirteen</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 