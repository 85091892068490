import React from 'react';

/**
 * Dashboard Projects component for displaying and managing user projects
 */
const DashboardProjects: React.FC = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">My Projects</h1>
      <p>Projects management interface</p>
    </div>
  );
};

export default DashboardProjects; 