import React, { useState } from 'react';

type BillingType = 'yearly' | 'monthly';

interface ExpandedFaqsState {
  faq1: boolean;
  faq2: boolean;
  faq3: boolean;
  faq4: boolean;
  faq5: boolean;
}

const PricingPage: React.FC = () => {
  const [billing, setBilling] = useState<BillingType>('yearly');
  const [expandedFaqs, setExpandedFaqs] = useState<ExpandedFaqsState>({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
  });

  return (
    <div className="max-w-5xl mx-auto px-4 py-12">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h3 className="text-xl font-bold text-gray-800 mb-1">SIMPLIFIED PRICING</h3>
        <h2 className="text-3xl font-bold text-gray-800 mb-6">Bibendum amet at molestie mattis.</h2>
        
        {/* Billing Toggle */}
        <div className="flex items-center justify-center gap-3 mb-8">
          <span className={`text-sm font-medium ${billing === 'yearly' ? 'text-gray-800' : 'text-gray-500'}`}>Yearly</span>
          <div 
            className="w-12 h-6 bg-blue-600 rounded-full p-1 cursor-pointer"
            onClick={() => setBilling(billing === 'yearly' ? 'monthly' : 'yearly')}
          >
            <div 
              className={`bg-white w-4 h-4 rounded-full transform transition-transform duration-300 ${billing === 'yearly' ? '' : 'translate-x-6'}`}
            ></div>
          </div>
          <span className={`text-sm font-medium ${billing === 'monthly' ? 'text-gray-800' : 'text-gray-500'}`}>Monthly</span>
        </div>
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
        {/* Freelancer Card */}
        <div className="border border-gray-200 rounded-lg p-6">
          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-800 mb-1">Freelancer</h3>
            <p className="text-sm text-gray-600">Quisque donec nibh diam tellus integer eros.</p>
          </div>
          
          <div className="mb-6">
            <p className="text-gray-400 line-through text-xl">$35</p>
            <p className="text-3xl font-bold text-gray-800">$25</p>
            <p className="text-sm text-gray-600">$25 USD per month, paid annually</p>
          </div>
          
          <button className="w-full bg-blue-600 text-white py-2 px-4 rounded font-medium mb-6">
            Get Started
          </button>
          
          <ul className="space-y-2">
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Eleven</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Twelve</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Thirteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fourteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fifteen</span>
            </li>
          </ul>
        </div>
        
        {/* Professional Card */}
        <div className="border border-gray-200 rounded-lg p-6 relative">
          <div className="absolute -top-3 left-0 right-0 flex justify-center">
            <span className="bg-gray-100 text-gray-800 text-xs px-3 py-1 rounded-full">
              Most Popular
            </span>
          </div>
          
          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-800 mb-1">Professional</h3>
            <p className="text-sm text-gray-600">Sed eget purus vulputate suscipit erat.</p>
          </div>
          
          <div className="mb-6">
            <p className="text-gray-400 line-through text-xl">$65</p>
            <p className="text-3xl font-bold text-gray-800">$55</p>
            <p className="text-sm text-gray-600">$55 USD per month, paid annually</p>
          </div>
          
          <button className="w-full bg-blue-600 text-white py-2 px-4 rounded font-medium mb-6">
            Get Started
          </button>
          
          <ul className="space-y-2">
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Eleven</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Twelve</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Thirteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fourteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fifteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Sixteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Seventeen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Eighteen</span>
            </li>
          </ul>
        </div>
        
        {/* Agency Card */}
        <div className="border border-gray-200 rounded-lg p-6">
          <div className="mb-6">
            <h3 className="text-xl font-bold text-gray-800 mb-1">Agency</h3>
            <p className="text-sm text-gray-600">Odio aenean purus non cursus blandit.</p>
          </div>
          
          <div className="mb-6">
            <p className="text-gray-400 line-through text-xl">$125</p>
            <p className="text-3xl font-bold text-gray-800">$95</p>
            <p className="text-sm text-gray-600">$95 USD per month, paid annually</p>
          </div>
          
          <button className="w-full bg-blue-600 text-white py-2 px-4 rounded font-medium mb-6">
            Get Started
          </button>
          
          <ul className="space-y-2">
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Eleven</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Twelve</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Thirteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fourteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Fifteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Sixteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Seventeen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Eighteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Nineteen</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Twenty</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Thirty</span>
            </li>
            <li className="flex items-start gap-2">
              <span className="text-green-500">✓</span>
              <span className="text-sm">Feature Forty</span>
            </li>
          </ul>
        </div>
      </div>
      
      {/* FAQ Section */}
      <div className="py-8 border-t border-gray-200">
        <div className="text-center mb-10">
          <h3 className="text-xl font-bold text-gray-800 mb-1">FREQUENTLY ASKED QUESTIONS</h3>
          <h2 className="text-3xl font-bold text-gray-800">Bibendum amet at molestie mattis.</h2>
        </div>
        
        <div className="space-y-4">
          <div className="border-b border-gray-200 pb-4">
            <button 
              className="flex justify-between items-center w-full py-2 text-left" 
              onClick={() => setExpandedFaqs({...expandedFaqs, faq1: !expandedFaqs.faq1})}
            >
              <span className="font-medium">Who should use the app?</span>
              <span className="text-xl">{expandedFaqs.faq1 ? '−' : '+'}</span>
            </button>
            {expandedFaqs.faq1 && (
              <div className="mt-2 text-gray-600 text-sm">
                <p>Our app is perfect for individuals, small businesses, and enterprise teams looking to streamline their workflow. Freelancers will benefit from the time-tracking features, professionals can leverage advanced project management tools, and agencies can utilize the comprehensive team collaboration capabilities.</p>
                <p className="mt-2">Whether you're a solo entrepreneur or part of a larger organization, our flexible pricing plans ensure you only pay for what you need.</p>
              </div>
            )}
          </div>
          
          <div className="border-b border-gray-200 pb-4">
            <button 
              className="flex justify-between items-center w-full py-2 text-left"
              onClick={() => setExpandedFaqs({...expandedFaqs, faq2: !expandedFaqs.faq2})}
            >
              <span className="font-medium">What is included with my subscription?</span>
              <span className="text-xl">{expandedFaqs.faq2 ? '−' : '+'}</span>
            </button>
            {expandedFaqs.faq2 && (
              <div className="mt-2 text-gray-600 text-sm">
                <p>All subscriptions include our core platform features, customer support, and regular updates. Depending on your plan, you'll get access to:</p>
                <ul className="list-disc pl-5 mt-2 space-y-1">
                  <li>Cloud storage and backups</li>
                  <li>Priority customer support</li>
                  <li>Advanced reporting and analytics</li>
                  <li>Team management features</li>
                  <li>API access for custom integrations</li>
                </ul>
                <p className="mt-2">Check the features list above for each plan to see the specific inclusions.</p>
              </div>
            )}
          </div>
          
          <div className="border-b border-gray-200 pb-4">
            <button 
              className="flex justify-between items-center w-full py-2 text-left"
              onClick={() => setExpandedFaqs({...expandedFaqs, faq3: !expandedFaqs.faq3})}
            >
              <span className="font-medium">Can I change my plan later?</span>
              <span className="text-xl">{expandedFaqs.faq3 ? '−' : '+'}</span>
            </button>
            {expandedFaqs.faq3 && (
              <div className="mt-2 text-gray-600 text-sm">
                <p>Yes, you can upgrade or downgrade your plan at any time. When upgrading, you'll get immediate access to the additional features and will be billed the prorated amount for the remainder of your billing cycle. When downgrading, the changes will take effect at the start of your next billing cycle.</p>
              </div>
            )}
          </div>
          
          <div className="border-b border-gray-200 pb-4">
            <button 
              className="flex justify-between items-center w-full py-2 text-left"
              onClick={() => setExpandedFaqs({...expandedFaqs, faq4: !expandedFaqs.faq4})}
            >
              <span className="font-medium">Do you offer a free trial?</span>
              <span className="text-xl">{expandedFaqs.faq4 ? '−' : '+'}</span>
            </button>
            {expandedFaqs.faq4 && (
              <div className="mt-2 text-gray-600 text-sm">
                <p>Yes, we offer a 14-day free trial on all plans. You can sign up without a credit card and test all the features included in your chosen plan. If you decide to continue, you can enter your payment details before the trial ends to avoid any interruption in service.</p>
              </div>
            )}
          </div>
          
          <div className="border-b border-gray-200 pb-4">
            <button 
              className="flex justify-between items-center w-full py-2 text-left"
              onClick={() => setExpandedFaqs({...expandedFaqs, faq5: !expandedFaqs.faq5})}
            >
              <span className="font-medium">What payment methods do you accept?</span>
              <span className="text-xl">{expandedFaqs.faq5 ? '−' : '+'}</span>
            </button>
            {expandedFaqs.faq5 && (
              <div className="mt-2 text-gray-600 text-sm">
                <p>We accept all major credit cards (Visa, MasterCard, American Express, Discover), PayPal, and bank transfers for annual plans. If you require alternative payment methods, please contact our sales team to discuss your options.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage; 