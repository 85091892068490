import React from 'react';
import { Link } from 'react-router-dom';

const Hero: React.FC = () => {
  return (
    <div 
      className="min-h-[500px] relative overflow-hidden pt-12 pb-24"
      style={{
        backgroundImage: 'url("/picture.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className="container mx-auto px-6 md:px-12 relative z-10">
        <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4 md:mb-6">DevCluster AI</h1>
        <h2 className="text-3xl md:text-4xl font-semibold text-gray-800 mb-6 md:mb-8">AI-driven solitions </h2>
        
        <p className="text-gray-700 max-w-2xl mb-8 md:mb-12">
          Pellentesque convallis accumsan suscipit aliquet eu diam quis nulla turpis. In mus massa 
          lectus laoreet sed semper bibendum id. Iaculis purus malesuada porttitor aliquam pulvinar. 
          Mi congue convallis tincidunt lobortis amet ultricies phasellus porttitor.
        </p>
        
        <div className="flex flex-col sm:flex-row gap-4">
          <Link 
            to="/free-trial" 
            className="bg-blue-500 text-white px-8 py-3 rounded font-medium hover:bg-blue-600 transition-all text-center"
          >
            Get free-trial!
          </Link>
          <Link 
            to="/tech-list" 
            className="border border-blue-500 text-blue-500 px-8 py-3 rounded font-medium hover:bg-blue-50 transition-all text-center"
          >
            Tech List
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero; 