import { useAuthContext } from "./useAuthContext";

/**
 * Hook to handle user logout functionality
 * @returns Object containing logout function
 */
export const useLogout = () => {
    const { logoutWithRedirect } = useAuthContext();
    
    const logout = async (): Promise<void> => {
        await logoutWithRedirect();
    };

    return { logout };
} 