import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Tableau config interface
interface TableauConfig {
  serverUrl: string | undefined;
  site: string | undefined;
  tokenUrl: string | undefined;
}

// Tableau dashboard interface
interface TableauDashboard {
  id: string;
  name: string;
  workbookId: string;
  workbookName: string;
  projectName: string;
  url: string;
  thumbnailUrl?: string;
  description?: string;
  tags?: string[];
}

// Tableau viz options interface
interface TableauVizOptions {
  hideTabs?: boolean;
  hideToolbar?: boolean;
  width?: string | number;
  height?: string | number;
  device?: string;
  onFirstInteractive?: () => void;
  onFirstVizSizeKnown?: () => void;
}

// Extend window to include Tableau object
declare global {
  interface Window {
    tableau?: {
      Viz: new (
        container: HTMLElement,
        url: string,
        options: TableauVizOptions
      ) => {
        dispose: () => void;
        refreshDataAsync: () => Promise<void>;
        exportImageAsync: () => Promise<string>;
      };
    };
  }
}

// Create Tableau API instance for backend communication
const tableauApi = createApiInstance(apiEndpoints.analytics);

// Configure auth interceptor in components
export const configureTableauApiWithAuth = (getToken: GetTokenFn): void => {
  tableauApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for Tableau API request:', e);
    }
    return config;
  });
};

// Tableau service for analytics integration
export const tableauService = {
  // Get Tableau configuration from environment
  getTableauConfig: (): TableauConfig => {
    return {
      serverUrl: import.meta.env.VITE_TABLEAU_URL,
      site: import.meta.env.VITE_TABLEAU_SITE,
      tokenUrl: import.meta.env.VITE_TABLEAU_TOKEN_URL
    };
  },

  // Get JWT token for Tableau embedding
  getTableauToken: async (): Promise<string | ApiResponse<any>> => {
    try {
      const response = await tableauApi.get<ApiResponse<{ token: string }>>('/tableau-token');
      return response.data.data.token;
    } catch (error) {
      return handleApiError(error, 'Tableau', 'get token');
    }
  },

  // Get available dashboards for the current user
  getDashboards: async (): Promise<ApiResponse<TableauDashboard[]>> => {
    try {
      const response = await tableauApi.get<ApiResponse<TableauDashboard[]>>('/dashboards');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Tableau', 'get dashboards');
    }
  },

  // Get specific dashboard information
  getDashboard: async (dashboardId: string): Promise<ApiResponse<TableauDashboard>> => {
    try {
      const response = await tableauApi.get<ApiResponse<TableauDashboard>>(`/dashboards/${dashboardId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Tableau', 'get dashboard');
    }
  },

  // Initialize a Tableau viz
  initTableauViz: (
    container: HTMLElement, 
    url: string, 
    options: TableauVizOptions = {}
  ) => {
    // This function requires the Tableau JavaScript API to be loaded
    if (!window.tableau || !window.tableau.Viz) {
      console.error('Tableau JavaScript API not loaded');
      return null;
    }

    // Configure default options
    const defaultOptions: TableauVizOptions = {
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: () => console.log('Tableau viz loaded')
    };

    // Create and return the viz object
    return new window.tableau.Viz(
      container,
      url,
      { ...defaultOptions, ...options }
    );
  },

  // Export dashboard as PNG
  exportAsPng: async (dashboardId: string): Promise<string | ApiResponse<any>> => {
    try {
      const response = await tableauApi.get(`/dashboards/${dashboardId}/export/png`, {
        responseType: 'blob'
      });
      return URL.createObjectURL(response.data);
    } catch (error) {
      return handleApiError(error, 'Tableau', 'export as PNG');
    }
  },

  // Export dashboard data as CSV
  exportAsCSV: async (dashboardId: string): Promise<string | ApiResponse<any>> => {
    try {
      const response = await tableauApi.get(`/dashboards/${dashboardId}/export/csv`, {
        responseType: 'blob'
      });
      return URL.createObjectURL(response.data);
    } catch (error) {
      return handleApiError(error, 'Tableau', 'export as CSV');
    }
  }
};

export default tableauService; 