import React from 'react';
import Hero from './Components/Hero';
import StatsSection from './Components/StatsSection';

const LandingPage: React.FC = () => {
  return (
    <div>
      <Hero />
      <StatsSection/>
      {/* Other sections of your landing page will go here */}
    </div>
  );
};

export default LandingPage; 