import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './Routes/Routes'
import { Auth0ProviderWithHistory } from './context/AuthContext'
import { Toaster } from 'react-hot-toast'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Initialize Stripe with public key
const stripeKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY as string
const stripePromise = loadStripe(stripeKey)

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')

createRoot(rootElement).render(
  <StrictMode>
    <Auth0ProviderWithHistory>
      <Elements stripe={stripePromise}>
        <Toaster/>
        <RouterProvider router={router} />
      </Elements>
    </Auth0ProviderWithHistory>
  </StrictMode>,
) 