import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { projectService } from '../../Dashboard/API/apiServices';
import { LogOutIcon } from 'lucide-react';
import { useLogout } from '../../../hooks/useLogout';
import { Project } from '../../../types';

const Sidebar: React.FC = () => {
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const navigate = useNavigate();
    const location = useLocation();
    const { projectID } = useParams<{ projectID?: string }>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    // Fetch user's projects when component mounts and user is available
    useEffect(() => {
        if (user?.user?._id) {
            fetchProjects();
        }
    }, [user]);

    // Fetch projects from the backend
    const fetchProjects = async (): Promise<void> => {
        try {
            setLoading(true);
            const userId = user?.user?._id;
            if (!userId) return;

            const response = await projectService.getAll(userId);
            if (response.success && response.projects) {
                setProjects(response.projects);
            } else {
                setProjects([]);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            setProjects([]);
        } finally {
            setLoading(false);
        }
    };

    const createNewProject = (): void => {
        navigate('/dashboard');
    };

    const formatLastModified = (dateString: string | Date | undefined): string => {
        if (!dateString) return 'N/A';
        
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    // Rest of the component implementation...

    return (
        <div className="flex flex-col h-screen bg-gray-900 text-white w-64 px-4 py-6">
            {/* Sidebar content */}
            <div className="flex items-center justify-between mb-8">
                <h1 className="text-xl font-bold">MyApp Dashboard</h1>
            </div>
            
            {/* User info */}
            <div className="flex items-center space-x-3 mb-8">
                <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center">
                    {user?.user?.name ? user.user.name.charAt(0).toUpperCase() : 'U'}
                </div>
                <div>
                    <h3 className="font-medium">{user?.user?.name || 'User'}</h3>
                    <p className="text-sm text-gray-400">{user?.user?.email || 'user@example.com'}</p>
                </div>
            </div>
            
            {/* Nav Links */}
            <nav className="flex-grow">
                <ul className="space-y-2">
                    <li>
                        <Link to="/dashboard" className={`flex items-center px-4 py-2 rounded-md transition ${!projectID ? 'bg-blue-700' : 'hover:bg-gray-800'}`}>
                            <span className="material-icons mr-3 text-lg">home</span>
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/projects" className={`flex items-center px-4 py-2 rounded-md transition ${location.pathname === '/dashboard/projects' ? 'bg-blue-700' : 'hover:bg-gray-800'}`}>
                            <span className="material-icons mr-3 text-lg">folder</span>
                            <span>Projects</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/settings" className={`flex items-center px-4 py-2 rounded-md transition ${location.pathname === '/dashboard/settings' ? 'bg-blue-700' : 'hover:bg-gray-800'}`}>
                            <span className="material-icons mr-3 text-lg">settings</span>
                            <span>Settings</span>
                        </Link>
                    </li>
                </ul>
            </nav>
            
            {/* Projects section */}
            <div className="mt-8">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="font-semibold text-lg">Recent Projects</h2>
                    <button 
                        onClick={createNewProject}
                        className="text-sm text-blue-400 hover:text-blue-300"
                    >
                        <span className="material-icons text-sm">add</span>
                    </button>
                </div>
                <ul className="space-y-2">
                    {loading ? (
                        <div className="text-sm text-gray-400">Loading projects...</div>
                    ) : (
                        projects.length > 0 ? (
                            projects.slice(0, 5).map(project => (
                                <li key={project._id}>
                                    <Link 
                                        to={`/dashboard/project/${project._id}`}
                                        className={`block px-3 py-2 text-sm rounded-md transition ${projectID === project._id ? 'bg-blue-700' : 'hover:bg-gray-800'}`}
                                    >
                                        <div className="font-medium truncate w-52">{project.name}</div>
                                        <div className="text-xs text-gray-400">Last active: {formatLastModified(project.lastActive)}</div>
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <div className="text-sm text-gray-400">No projects yet</div>
                        )
                    )}
                </ul>
            </div>
            
            {/* Logout */}
            <button
                onClick={() => logout()}
                className="mt-8 flex items-center px-4 py-2 text-red-400 hover:text-red-300 transition"
            >
                <LogOutIcon className="mr-3 h-5 w-5" />
                <span>Logout</span>
            </button>
        </div>
    );
};

export default Sidebar; 