import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLogout } from "../../../hooks/useLogout";
import { LogOut, LogOutIcon } from 'lucide-react';

const Navbar: React.FC = () => {
  const { user } = useAuthContext();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const { logout } = useLogout();

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);

    // Prevent scrolling when menu is open
    if (!isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const closeMenu = (): void => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  // Close menu when window is resized to desktop size
  useEffect(() => {
    const handleResize = (): void => {
      if (window.innerWidth >= 768 && isMenuOpen) {
        closeMenu();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMenuOpen]);

  return (
    <nav className="bg-white w-full fixed top-0 left-0 right-0 shadow-md z-50">
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex justify-between items-center py-4">
          {/* Logo and Company Name */}
          <Link to="/" className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
            </svg>
            <span className="font-semibold text-xl">DevCluster AI</span>
          </Link>

          {/* Desktop Navigation - Centered */}
          <div className="hidden md:flex items-center justify-center">
            <div className="flex gap-8">
              <Link to="/" className="text-gray-800 hover:text-blue-600">
                Home
              </Link>
              <Link to="/pricing" className="text-gray-800 hover:text-blue-600">
                Pricing
              </Link>
              <Link to="/about" className="text-gray-800 hover:text-blue-600">
                About
              </Link>
              <Link to="/contactUs" className="text-gray-800 hover:text-blue-600">
                Contact Us
              </Link>
            </div>
          </div>

          {/* Authentication Buttons - Desktop */}
          {!user && <div className="hidden md:flex gap-4">
            <Link
              to="/login"
              className="border border-blue-500 text-blue-500 px-4 py-2 rounded hover:bg-blue-50"
            >
              Log In
            </Link>
            <Link
              to="/signup"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Sign Up
            </Link>
          </div>}
          {user && <div className="hidden md:flex gap-4">
            <Link
              to="/dashboard"
              className="border border-blue-500 text-blue-500 px-4 py-2 rounded hover:bg-blue-50"
            >
              Projects
            </Link>
            <div className='flex items-center'>
              <div className='w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-gray-600'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <div className='ml-3 flex-1 min-w-0'>
                <p className='text-sm font-medium text-gray-900 truncate'>{user?.user?.firstName + " " + user?.user?.lastName}</p>
                <p className='text-xs text-gray-500 truncate'>{user?.user?.email}</p>
              </div>
            </div>
            <button
              onClick={logout}
              className=" text-slate-500 px-4 py-2 rounded hover:bg-blue-50 cursor-pointer"
            >
              <LogOutIcon />
            </button>
          </div>}

          {/* Mobile Hamburger Button */}
          <button
            className="md:hidden flex items-center"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Navigation Menu Overlay with transition */}
      <div
        className={`fixed inset-0 bg-white transform transition-transform duration-300 ease-in-out z-40 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <div className="container mx-auto px-6 pt-20 pb-8">
          {/* Close Button */}
          <button
            className="absolute top-4 right-6 p-2"
            onClick={closeMenu}
            aria-label="Close menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="flex flex-col gap-4">
            <Link
              to="/"
              className="text-gray-800 hover:text-blue-600 py-2 text-xl"
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/pricing"
              className="text-gray-800 hover:text-blue-600 py-2 text-xl"
              onClick={closeMenu}
            >
              Pricing
            </Link>
            <Link
              to="/about"
              className="text-gray-800 hover:text-blue-600 py-2 text-xl"
              onClick={closeMenu}
            >
              About
            </Link>
            <Link
              to="/contactUs"
              className="text-gray-800 hover:text-blue-600 py-2 text-xl"
              onClick={closeMenu}
            >
              Contact Us
            </Link>
            {!user && <div className="flex flex-col gap-3 pt-6">
              <Link
                to="/login"
                className="border border-blue-500 text-blue-500 px-4 py-3 rounded hover:bg-blue-50 text-center"
                onClick={closeMenu}
              >
                Log In
              </Link>
              <Link
                to="/signup"
                className="bg-blue-500 text-white px-4 py-3 rounded hover:bg-blue-600 text-center"
                onClick={closeMenu}
              >
                Sign Up
              </Link>
            </div>}
            {user && <div className="flex flex-col gap-3 pt-6">
              <Link
                to="/dashboard"
                className="border border-blue-500 text-blue-500 px-4 py-3 rounded hover:bg-blue-50 text-center"
                onClick={closeMenu}
              >
                Projects
              </Link>
              <div className='flex items-center justify-center w-full'>
                <div className='w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-gray-600'>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
                <div className='ml-3 flex-1 min-w-0'>
                  <p className='text-sm font-medium text-gray-900 truncate'>{user?.user?.firstName + " " + user?.user?.lastName}</p>
                  <p className='text-xs text-gray-500 truncate'>{user?.user?.email}</p>
                </div>
                <button
                  onClick={logout}
                  className=" text-slate-500 px-4 py-2 rounded hover:bg-blue-50 cursor-pointer"
                >
                  <LogOutIcon />
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 