import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { analyticsService, configureAnalyticsApiWithAuth } from '../../api/analyticsService';
import Loader from '../Loader';
import { ApiResponse } from '../../types/api';

// Use the same interface declared in analyticsService.ts
interface DashboardData {
  summary: {
    totalProjects: number;
    activeProjects: number;
    totalUsage: number;
    usageLimit: number;
  };
  recentActivity: Array<{
    id: string;
    type: string;
    description: string;
    timestamp: string;
  }>;
  usageMetrics: Array<{
    name: string;
    value: number;
    unit: string;
  }>;
}

const AnalyticsDashboard: React.FC = () => {
  const { user, getToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [analytics, setAnalytics] = useState<ApiResponse<DashboardData> | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      // Configure API with Auth0 token
      configureAnalyticsApiWithAuth(getToken);
      
      const fetchAnalytics = async (): Promise<void> => {
        try {
          setIsLoading(true);
          const data = await analyticsService.getDashboardData(user.sub);
          setAnalytics(data);
          setError(null);
        } catch (err: any) {
          console.error('Error fetching analytics:', err);
          setError('Failed to load analytics data. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchAnalytics();
    }
  }, [user, getToken]);

  if (isLoading) {
    return <Loader text="Loading analytics..." />;
  }

  if (error) {
    return (
      <div className="text-center p-8">
        <h2 className="text-xl font-bold text-red-600">Error</h2>
        <p className="mt-2">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Analytics Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">User Activity</h2>
          <p className="text-gray-600">Analytics data coming soon...</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Project Performance</h2>
          <p className="text-gray-600">Analytics data coming soon...</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4">Resource Usage</h2>
          <p className="text-gray-600">Analytics data coming soon...</p>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsDashboard; 