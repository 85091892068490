import { AuthContext } from "../context/AuthContext";
import { useContext } from 'react';
import type { AuthContextValue } from "../context/AuthContext";

/**
 * Hook to access the Auth context throughout the application
 * @returns The current Auth context value
 * @throws Error if used outside of AuthContextProvider
 */
export const useAuthContext = (): AuthContextValue => {
    const context = useContext(AuthContext);
    
    if (context === undefined || context === null) {
        throw new Error('useAuthContext must be used within an AuthContextProvider');
    }
    
    return context;
} 