import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Create billing API instance
const billingApi = createApiInstance(apiEndpoints.billing);

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Subscription interface
export interface Subscription {
  id: string;
  status: 'active' | 'canceled' | 'past_due' | 'trialing';
  plan: {
    id: string;
    name: string;
    amount: number;
    currency: string;
    interval: 'month' | 'year';
  };
  currentPeriodEnd: string;
  cancelAtPeriodEnd: boolean;
}

// Invoice interface
export interface Invoice {
  id: string;
  number: string;
  date: string;
  amount: number;
  currency: string;
  status: 'paid' | 'open' | 'draft' | 'void' | 'uncollectible';
  url: string;
}

// Payment method interface
export interface PaymentMethod {
  id: string;
  type: 'card' | 'bank_account' | 'other';
  card?: {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
  };
  isDefault: boolean;
}

// Configure auth interceptor in components
export const configureBillingApiWithAuth = (getToken: GetTokenFn): void => {
  billingApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for billing API request:', e);
    }
    return config;
  });
};

// Billing API services
export const billingService = {
  // Get user's current subscription
  getSubscription: async (userId: string): Promise<ApiResponse<Subscription>> => {
    try {
      const response = await billingApi.get<ApiResponse<Subscription>>(`/subscriptions/${userId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'get subscription');
    }
  },

  // Get user's invoices
  getInvoices: async (userId: string, limit: number = 10): Promise<ApiResponse<Invoice[]>> => {
    try {
      const response = await billingApi.get<ApiResponse<Invoice[]>>(`/invoices/${userId}`, {
        params: { limit }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'get invoices');
    }
  },

  // Get user's payment methods
  getPaymentMethods: async (userId: string): Promise<ApiResponse<PaymentMethod[]>> => {
    try {
      const response = await billingApi.get<ApiResponse<PaymentMethod[]>>(`/payment-methods/${userId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'get payment methods');
    }
  },

  // Add a payment method
  addPaymentMethod: async (userId: string, paymentMethodId: string): Promise<ApiResponse<PaymentMethod>> => {
    try {
      const response = await billingApi.post<ApiResponse<PaymentMethod>>(`/payment-methods/${userId}`, { paymentMethodId });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'add payment method');
    }
  },

  // Delete a payment method
  deletePaymentMethod: async (userId: string, paymentMethodId: string): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      const response = await billingApi.delete<ApiResponse<{ success: boolean }>>(`/payment-methods/${userId}/${paymentMethodId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'delete payment method');
    }
  },

  // Create a Stripe checkout session
  createCheckoutSession: async (userId: string, priceId: string): Promise<ApiResponse<{ sessionId: string; url: string }>> => {
    try {
      const response = await billingApi.post<ApiResponse<{ sessionId: string; url: string }>>('/checkout', { userId, priceId });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'create checkout session');
    }
  },

  // Create a Stripe customer portal session
  createCustomerPortalSession: async (userId: string): Promise<ApiResponse<{ url: string }>> => {
    try {
      const response = await billingApi.post<ApiResponse<{ url: string }>>('/customer-portal', { userId });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'create customer portal session');
    }
  },

  // Cancel subscription
  cancelSubscription: async (userId: string, subscriptionId: string): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      const response = await billingApi.post<ApiResponse<{ success: boolean }>>(`/subscriptions/${userId}/cancel`, { subscriptionId });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Billing', 'cancel subscription');
    }
  }
};

export default billingService; 