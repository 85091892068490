import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse, User } from '../types/api';

// Create auth API instance
const authApi = createApiInstance(apiEndpoints.auth);

// Types for auth service
interface TokenVerificationResponse {
  valid: boolean;
  decoded?: Record<string, any>;
}

interface UserInfoResponse extends User {
  sub: string;
  nickname?: string;
  picture?: string;
  email_verified?: boolean;
  [key: string]: any;
}

interface UserMetadataUpdateRequest {
  user_metadata: Record<string, any>;
}

// Auth API service
export const authService = {
  // Verify a token
  verifyToken: async (token: string): Promise<ApiResponse<TokenVerificationResponse>> => {
    try {
      const response = await authApi.post<ApiResponse<TokenVerificationResponse>>('/verify', { token });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Auth', 'token verification');
    }
  },

  // Get user info from token
  getUserInfo: async (token: string): Promise<ApiResponse<UserInfoResponse>> => {
    try {
      const response = await authApi.get<ApiResponse<UserInfoResponse>>('/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Auth', 'get user info');
    }
  },

  // Update Auth0 user metadata - requires Auth0 Management API access
  updateUserMetadata: async (userId: string, metadata: Record<string, any>, token: string): Promise<ApiResponse<User>> => {
    try {
      const response = await authApi.patch<ApiResponse<User>>(
        `/users/${userId}`, 
        { user_metadata: metadata } as UserMetadataUpdateRequest, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Auth', 'update user metadata');
    }
  }
};

export default authService; 