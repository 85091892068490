import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Create projects API instance
const projectsApi = createApiInstance(apiEndpoints.projects);

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Project interface
interface Project {
  id: string;
  name: string;
  description?: string;
  userId: string;
  status: 'active' | 'archived' | 'completed';
  createdAt: string;
  updatedAt: string;
  settings?: Record<string, any>;
  teamMembers?: string[];
}

// Project creation data interface
interface ProjectCreateData {
  name: string;
  description?: string;
  userId: string;
  status?: 'active' | 'archived' | 'completed';
  settings?: Record<string, any>;
  teamMembers?: string[];
}

// Project update data interface
interface ProjectUpdateData {
  name?: string;
  description?: string;
  status?: 'active' | 'archived' | 'completed';
  settings?: Record<string, any>;
  teamMembers?: string[];
}

// Configure auth interceptor in components
export const configureProjectsApiWithAuth = (getToken: GetTokenFn): void => {
  projectsApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for project API request:', e);
    }
    return config;
  });
};

// Project API services
export const projectService = {
  // Get all projects for a specific user
  getAll: async (userId: string): Promise<ApiResponse<Project[]>> => {
    try {
      if (!userId) {
        throw new Error('User ID is required to fetch projects');
      }
      
      const response = await projectsApi.get<ApiResponse<Project[]>>(`/?userId=${userId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Project', 'fetch all');
    }
  },

  // Get a single project
  getById: async (id: string): Promise<ApiResponse<Project>> => {
    try {
      const response = await projectsApi.get<ApiResponse<Project>>(`/${id}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Project', `fetch by ID ${id}`);
    }
  },

  // Create a new project
  create: async (projectData: ProjectCreateData): Promise<ApiResponse<Project>> => {
    try {
      if (!projectData.userId) {
        throw new Error('User ID is required to create a project');
      }
      
      const response = await projectsApi.post<ApiResponse<Project>>('/', projectData);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Project', 'create');
    }
  },

  // Update a project
  update: async (id: string, projectData: ProjectUpdateData): Promise<ApiResponse<Project>> => {
    try {
      const response = await projectsApi.put<ApiResponse<Project>>(`/${id}`, projectData);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Project', `update ${id}`);
    }
  },

  // Delete a project
  delete: async (id: string, userId: string): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      if (!userId) {
        throw new Error('User ID is required to delete a project');
      }
      
      const response = await projectsApi.delete<ApiResponse<{ success: boolean }>>(`/${id}`, {
        data: { userId }
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Project', `delete ${id}`);
    }
  }
};

export default projectService; 