import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { usageService, configureUsageApiWithAuth } from '../../api/usageService';
import Loader from '../Loader';

interface UsageData {
  apiCalls: number;
  storage: number;
  projects: number;
  history: Array<{
    date: string;
    value: number;
    type: string;
  }>;
}

interface QuotaData {
  apiCalls: {
    limit: number;
    used: number;
    remaining: number;
  };
  storage: {
    limit: number;
    used: number;
    remaining: number;
  };
  projects: {
    limit: number;
    count: number;
    remaining: number;
  };
}

type TimeRange = 'week' | 'month' | 'year';

const UsageMetrics: React.FC = () => {
  const { user, getToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [usage, setUsage] = useState<UsageData | null>(null);
  const [quota, setQuota] = useState<QuotaData | null>(null);
  const [timeRange, setTimeRange] = useState<TimeRange>('month');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user) {
      // Configure API with Auth0 token
      configureUsageApiWithAuth(getToken);
      
      const fetchUsageData = async (): Promise<void> => {
        try {
          setIsLoading(true);
          
          // Fetch usage data
          const usageData = await usageService.getUserUsage(user.sub, timeRange);
          setUsage(usageData);
          
          // Fetch quota data
          const quotaData = await usageService.getQuota(user.sub);
          setQuota(quotaData);
          
          setError(null);
        } catch (err: any) {
          console.error('Error fetching usage data:', err);
          setError('Failed to load usage data. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchUsageData();
    }
  }, [user, getToken, timeRange]);

  const handleTimeRangeChange = (range: TimeRange): void => {
    setTimeRange(range);
  };

  if (isLoading) {
    return <Loader text="Loading usage metrics..." />;
  }

  if (error) {
    return (
      <div className="text-center p-8">
        <h2 className="text-xl font-bold text-red-600">Error</h2>
        <p className="mt-2">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Usage Metrics</h1>
        
        <div className="flex space-x-2">
          <button 
            className={`px-4 py-2 rounded ${timeRange === 'week' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeRangeChange('week')}
            type="button"
          >
            Week
          </button>
          <button 
            className={`px-4 py-2 rounded ${timeRange === 'month' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeRangeChange('month')}
            type="button"
          >
            Month
          </button>
          <button 
            className={`px-4 py-2 rounded ${timeRange === 'year' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
            onClick={() => handleTimeRangeChange('year')}
            type="button"
          >
            Year
          </button>
        </div>
      </div>
      
      {/* Quota Overview */}
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-lg font-semibold mb-4">Quota Overview</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="text-sm font-medium text-gray-500">API Calls</h3>
            <p className="text-gray-600">Data coming soon...</p>
          </div>
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="text-sm font-medium text-gray-500">Storage</h3>
            <p className="text-gray-600">Data coming soon...</p>
          </div>
          <div className="bg-gray-50 p-4 rounded">
            <h3 className="text-sm font-medium text-gray-500">Projects</h3>
            <p className="text-gray-600">Data coming soon...</p>
          </div>
        </div>
      </div>
      
      {/* Usage History */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Usage History</h2>
        <p className="text-gray-600">Historical usage data coming soon...</p>
      </div>
    </div>
  );
};

export default UsageMetrics; 