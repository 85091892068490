import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Component/Sidebar';
import { useAuthContext } from '../../hooks/useAuthContext';
import Loader from '../../Components/Loader';

/**
 * Dashboard layout component that includes the sidebar and outlet for child routes
 */
const DashboardLayout: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(true);

  // Redirect to login if not authenticated
  useEffect(() => {
    // Short timeout to allow auth to initialize
    const timer = setTimeout(() => {
      if (!user) {
        navigate('/login');
      }
      setIsCheckingAuth(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [user, navigate]);

  // Show loading state while checking authentication
  if (isCheckingAuth) {
    return <Loader />;
  }

  // Only render dashboard if user is authenticated
  if (!user) {
    return null;
  }

  return (
    <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
      {/* Sidebar */}
      <Sidebar />

      {/* Main content */}
      <div className="flex-1 overflow-auto">
        <main className="p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout; 