import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

const HomeLayout: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      {/* Add padding to account for fixed navbar */}
      <div className="pt-16 flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default HomeLayout; 