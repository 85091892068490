// API Services export file
import authService from './authService';
import userService from './userService';
import projectService from './projectService';
import analyticsService from './analyticsService';
import billingService from './billingService';
import usageService from './usageService';
import storageService from './storageService';
import stripeService from './stripeService';
import tableauService from './tableauService';
import realtimeService from './realtimeService';

// Authentication configuration helpers
import { configureUsersApiWithAuth } from './userService';
import { configureProjectsApiWithAuth } from './projectService';
import { configureAnalyticsApiWithAuth } from './analyticsService';
import { configureBillingApiWithAuth } from './billingService';
import { configureUsageApiWithAuth } from './usageService';
import { configureStripeApiWithAuth } from './stripeService';
import { configureTableauApiWithAuth } from './tableauService';

// Token getter function type
type GetTokenFn = () => Promise<string>;

// Services collection interface
interface ApiServices {
  auth: typeof authService;
  users: typeof userService;
  projects: typeof projectService;
  analytics: typeof analyticsService;
  billing: typeof billingService;
  usage: typeof usageService;
  storage: typeof storageService;
  stripe: typeof stripeService;
  tableau: typeof tableauService;
  realtime: typeof realtimeService;
}

// Configure all APIs with authentication
export const configureAllApisWithAuth = (getToken: GetTokenFn): void => {
  configureUsersApiWithAuth(getToken);
  configureProjectsApiWithAuth(getToken);
  configureAnalyticsApiWithAuth(getToken);
  configureBillingApiWithAuth(getToken);
  configureUsageApiWithAuth(getToken);
  configureStripeApiWithAuth(getToken);
  configureTableauApiWithAuth(getToken);
};

// Export all services
export {
  authService,
  userService,
  projectService,
  analyticsService,
  billingService,
  usageService,
  storageService,
  stripeService,
  tableauService,
  realtimeService
};

// Default export all services as an object
const services: ApiServices = {
  auth: authService,
  users: userService,
  projects: projectService,
  analytics: analyticsService,
  billing: billingService,
  usage: usageService,
  storage: storageService,
  stripe: stripeService,
  tableau: tableauService,
  realtime: realtimeService
};

export default services; 