import React from 'react';

const DashboardSettings: React.FC = () => {
  return (
    <div>
      Dashboard Settings
    </div>
  );
};

export default DashboardSettings; 