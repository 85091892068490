import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// Stripe payment intent interface
interface PaymentIntent {
  id: string;
  clientSecret: string;
  amount: number;
  currency: string;
  status: string;
}

// Stripe setup intent interface
interface SetupIntent {
  id: string;
  clientSecret: string;
  status: string;
}

// Stripe payment method interface
interface PaymentMethod {
  id: string;
  type: string;
  card?: {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
  };
  billingDetails?: {
    name: string;
    email: string;
    address?: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
    };
  };
}

// Stripe subscription interface
interface Subscription {
  id: string;
  status: 'active' | 'canceled' | 'past_due' | 'trialing' | 'incomplete' | 'incomplete_expired';
  currentPeriodEnd: number;
  currentPeriodStart: number;
  plan: {
    id: string;
    amount: number;
    currency: string;
    interval: 'month' | 'year' | 'week' | 'day';
    intervalCount: number;
    product: string;
    nickname?: string;
  };
  cancelAtPeriodEnd: boolean;
}

// Stripe plan/product interface
interface Plan {
  id: string;
  nickname: string;
  amount: number;
  currency: string;
  interval: 'month' | 'year' | 'week' | 'day';
  intervalCount: number;
  product: {
    id: string;
    name: string;
    description?: string;
    metadata?: Record<string, string>;
  };
  metadata?: Record<string, string>;
}

// Stripe invoice interface
interface Invoice {
  id: string;
  number: string;
  created: number;
  dueDate?: number;
  amount: number;
  amountPaid: number;
  amountRemaining: number;
  currency: string;
  status: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
  subscription?: string;
  periodStart: number;
  periodEnd: number;
  lineItems: Array<{
    id: string;
    amount: number;
    currency: string;
    description: string;
    quantity?: number;
  }>;
  pdf?: string;
}

// Create Stripe API instance
const stripeApi = createApiInstance(apiEndpoints.billing);

// Configure auth interceptor in components
export const configureStripeApiWithAuth = (getToken: GetTokenFn): void => {
  stripeApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for stripe API request:', e);
    }
    return config;
  });
};

// Stripe service functions
export const stripeService = {
  // Get Stripe public key from environment
  getPublicKey: (): string | undefined => {
    return import.meta.env.VITE_STRIPE_PUBLIC_KEY;
  },

  // Check if we're in test mode
  isTestMode: (): boolean => {
    return import.meta.env.VITE_ENABLE_STRIPE_TEST_MODE === 'true';
  },

  // Create a payment intent
  createPaymentIntent: async (amount: number, currency: string = 'usd'): Promise<ApiResponse<PaymentIntent>> => {
    try {
      const response = await stripeApi.post<ApiResponse<PaymentIntent>>('/payment-intents', { amount, currency });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'create payment intent');
    }
  },

  // Create a setup intent for saving payment methods
  createSetupIntent: async (): Promise<ApiResponse<SetupIntent>> => {
    try {
      const response = await stripeApi.post<ApiResponse<SetupIntent>>('/setup-intents');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'create setup intent');
    }
  },

  // Get customer payment methods
  getPaymentMethods: async (): Promise<ApiResponse<PaymentMethod[]>> => {
    try {
      const response = await stripeApi.get<ApiResponse<PaymentMethod[]>>('/payment-methods');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'get payment methods');
    }
  },

  // Delete a payment method
  deletePaymentMethod: async (paymentMethodId: string): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      const response = await stripeApi.delete<ApiResponse<{ success: boolean }>>(`/payment-methods/${paymentMethodId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'delete payment method');
    }
  },

  // Get customer's active subscriptions
  getSubscriptions: async (): Promise<ApiResponse<Subscription[]>> => {
    try {
      const response = await stripeApi.get<ApiResponse<Subscription[]>>('/subscriptions');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'get subscriptions');
    }
  },

  // Create a new subscription
  createSubscription: async (priceId: string, paymentMethodId: string): Promise<ApiResponse<Subscription>> => {
    try {
      const response = await stripeApi.post<ApiResponse<Subscription>>('/subscriptions', {
        priceId,
        paymentMethodId
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'create subscription');
    }
  },

  // Cancel a subscription
  cancelSubscription: async (subscriptionId: string): Promise<ApiResponse<Subscription>> => {
    try {
      const response = await stripeApi.post<ApiResponse<Subscription>>(`/subscriptions/${subscriptionId}/cancel`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'cancel subscription');
    }
  },

  // Change subscription plan
  changeSubscription: async (subscriptionId: string, newPriceId: string): Promise<ApiResponse<Subscription>> => {
    try {
      const response = await stripeApi.post<ApiResponse<Subscription>>(`/subscriptions/${subscriptionId}/change`, {
        newPriceId
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'change subscription');
    }
  },

  // Get available plans/products
  getPlans: async (): Promise<ApiResponse<Plan[]>> => {
    try {
      const response = await stripeApi.get<ApiResponse<Plan[]>>('/plans');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'get plans');
    }
  },

  // Get customer's invoices
  getInvoices: async (): Promise<ApiResponse<Invoice[]>> => {
    try {
      const response = await stripeApi.get<ApiResponse<Invoice[]>>('/invoices');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Stripe', 'get invoices');
    }
  }
};

export default stripeService; 