import React, { useState, ChangeEvent, FormEvent } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import GoogleLoginButton from '../../Components/auth/GoogleLoginButton';
import Loader from '../../Components/Loader';
import { Auth0ContextInterface } from '@auth0/auth0-react';

// Form data interface
interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const Login: React.FC = () => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
    rememberMe: false
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginWithRedirect } = useAuthContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    
    try {
      setIsLoading(true);
      // Use Auth0 login
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: "login",
          login_hint: formData.email
        }
      });
      toast.success("Redirecting to login...");
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed. Please try again.");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader text="Redirecting to login..." />;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left side - Logo area */}
      <div className="w-full lg:w-5/12 bg-gray-200 flex items-center justify-center py-12 lg:py-0">
        <div className="w-48 h-48 md:w-64 md:h-64 p-4 bg-white flex items-center justify-center">
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <svg viewBox="0 0 100 100" className="w-4/5 h-4/5 text-white">
              <path
                fill="currentColor"
                d="M10 10 L90 10 L50 50 L10 10 Z M10 90 L90 90 L50 50 L10 90 Z M10 10 L10 90 L50 50 L10 10 Z M90 10 L90 90 L50 50 L90 10 Z"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* Right side - Login form */}
      <div className="w-full lg:w-7/12 bg-white p-6 sm:p-8 flex items-center justify-center">
        <div className="w-full max-w-md px-4">
          <h1 className="text-3xl font-semibold mb-6 sm:mb-8 text-gray-800">Log In</h1>

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                className="w-full p-2 border border-gray-300 rounded bg-gray-50"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                className="w-full p-2 border border-gray-300 rounded bg-gray-50"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="text-xs text-gray-600 mb-4">
              It must be a combination of minimum 8 letters, numbers, and symbols.
            </div>

            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6 gap-3 sm:gap-0">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded cursor-pointer"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                />
                <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-700 cursor-pointer">
                  Remember me
                </label>
              </div>
              <Link to="/forgot-password" className="text-sm text-blue-600 hover:underline">
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200 cursor-pointer"
            >
              Log In
            </button>

            <div className="my-6 flex items-center">
              <div className="flex-grow h-px bg-gray-300"></div>
              <span className="mx-4 text-sm text-gray-500">OR</span>
              <div className="flex-grow h-px bg-gray-300"></div>
            </div>

            <div className="grid grid-cols-1 gap-4">
              <GoogleLoginButton label="Log in with Google" />
              
              <button
                type="button"
                className="flex justify-center items-center border border-gray-300 rounded py-2 px-4 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
              >
                <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" />
                </svg>
                Log in with Facebook
              </button>
            </div>

            <div className="mt-8 text-center text-sm">
              <span className="text-gray-600">No account yet?</span>{' '}
              <Link to="/signup" className="text-blue-600 hover:underline">
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login; 