import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import backendRoutes from '../../../API_Endpoint';
import { 
  Project, 
  ProjectResponse, 
  UserResponse, 
  UserCredentials, 
  UserData 
} from '../../../types';

// Create axios instance with base URL
const API: AxiosInstance = axios.create({
  baseURL: `${backendRoutes}/api`, // Using port 4000
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add token to request if available
API.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Project API services
export const projectService = {
  // Get all projects for a specific user
  getAll: async (userId: string): Promise<ProjectResponse> => {
    try {
      if (!userId) {
        throw new Error('User ID is required to fetch projects');
      }
      
      const response = await API.get(`/projects?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
    }
  },

  // Get a single project
  getById: async (id: string): Promise<ProjectResponse> => {
    try {
      const response = await API.get(`/projects/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching project ${id}:`, error);
      throw error;
    }
  },

  // Create a new project with direct user ID
  create: async (projectData: Project): Promise<ProjectResponse> => {
    try {
      // projectData should contain userId directly from the component
      if (!projectData.userId) {
        throw new Error('User ID is required to create a project');
      }
      
      const response = await API.post('/projects', projectData);
      return response.data;
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  },

  // Update a project
  update: async (id: string, projectData: Partial<Project>): Promise<ProjectResponse> => {
    try {
      const response = await API.put(`/projects/${id}`, projectData);
      return response.data;
    } catch (error) {
      console.error(`Error updating project ${id}:`, error);
      throw error;
    }
  },

  // Delete a project with direct user ID
  delete: async (id: string, userId: string): Promise<ProjectResponse> => {
    try {
      if (!userId) {
        throw new Error('User ID is required to delete a project');
      }
      
      const response = await API.delete(`/projects/${id}`, {
        data: { userId } // Pass userId in request body
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting project ${id}:`, error);
      throw error;
    }
  }
};

// User API services
export const userService = {
  // Get current user profile
  getProfile: async (): Promise<UserResponse> => {
    try {
      const response = await API.get('/users/profile');
      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  },

  // Login user
  login: async (credentials: UserCredentials): Promise<UserResponse> => {
    try {
      const response = await API.post('/auth/login', credentials);
      if (response.data.success && response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  // Register user
  register: async (userData: UserData): Promise<UserResponse> => {
    try {
      const response = await API.post('/auth/register', userData);
      if (response.data.success && response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  // Logout user
  logout: (): void => {
    localStorage.removeItem('token');
  }
};

export default API; 