import React, { createContext, useEffect, useReducer, ReactNode } from "react";
import { Auth0Provider, useAuth0, Auth0ContextInterface, User } from "@auth0/auth0-react";
import backendRoutes from "../API_Endpoint";
import { configureAllApisWithAuth } from "../api";
import { realtimeService } from "../api/realtimeService";

// Extended user with token
export interface AuthUser extends User {
  token?: string;
}

// Auth state interface
interface AuthState {
  user: AuthUser | null;
  isInitialized: boolean;
}

// Auth action types
type AuthAction = 
  | { type: 'LOGIN'; payload: AuthUser }
  | { type: 'LOGOUT' }
  | { type: 'INITIALIZE' };

// Context value interface
export interface AuthContextValue extends AuthState {
  dispatch: React.Dispatch<AuthAction>;
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect'];
  loginWithGoogle: () => void;
  logoutWithRedirect: () => void;
  getToken: Auth0ContextInterface['getAccessTokenSilently'];
}

// Component props interfaces
interface Auth0ProviderWithHistoryProps {
  children: ReactNode;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

// Create the context with a default value matching the interface
export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

// Auth reducer for state management
export const authReducer = (state: AuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case 'LOGIN':
            return { user: action.payload, isInitialized: true };
        case 'LOGOUT':
            return { user: null, isInitialized: true };
        case 'INITIALIZE':
            return { ...state, isInitialized: true };
        default:
            return state;
    }
};

// Auth0 wrapper for the application
export const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
    // Auth0 configuration
    const domain = import.meta.env.VITE_AUTH0_DOMAIN || "your-auth0-domain.auth0.com";
    const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID || "your-auth0-client-id";
    const audience = import.meta.env.VITE_AUTH0_AUDIENCE || "https://api.product-usage-platform.local";
    const redirectUri = window.location.origin + '/callback';

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                // Add Google as a connection option
                connection: "google-oauth2",
                // Add the audience for your API
                audience: audience,
                // Add scope for specific Google permissions if needed
                scope: "openid profile email"
            }}
            cacheLocation="localstorage"
        >
            <AuthContextProvider>
                {children}
            </AuthContextProvider>
        </Auth0Provider>
    );
};

// Function to login with Google specifically
export const loginWithGoogle = (
  loginWithRedirect: Auth0ContextInterface['loginWithRedirect']
): void => {
    loginWithRedirect({
        authorizationParams: {
            connection: 'google-oauth2'
        }
    });
};

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const { user: auth0User, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        isInitialized: false
    });

    // Sync Auth0 state with our context
    useEffect(() => {
        const initializeAuth = async (): Promise<void> => {
            if (isLoading) {
                return;
            }

            if (isAuthenticated && auth0User) {
                try {
                    // Get the token from Auth0
                    const token = await getAccessTokenSilently();
                    
                    // Configure all API services with authentication
                    configureAllApisWithAuth(() => getAccessTokenSilently());
                    
                    // Initialize real-time connection if enabled
                    if (import.meta.env.VITE_ENABLE_REAL_TIME_UPDATES === 'true') {
                        realtimeService.connect(token);
                        // Register for user-specific notifications
                        realtimeService.registerForNotifications(token);
                    }
                    
                    // Format the user data
                    const formattedUser: AuthUser = {
                        ...auth0User,
                        token
                    };

                    // Update the auth context
                    dispatch({ type: 'LOGIN', payload: formattedUser });
                } catch (error) {
                    console.error('Error getting token:', error);
                    dispatch({ type: 'INITIALIZE' });
                }
            } else {
                dispatch({ type: 'INITIALIZE' });
            }
        };

        initializeAuth();
    }, [isAuthenticated, isLoading, auth0User, getAccessTokenSilently]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            // Disconnect from WebSocket when the app unmounts
            if (import.meta.env.VITE_ENABLE_REAL_TIME_UPDATES === 'true') {
                realtimeService.disconnect();
            }
        };
    }, []);

    // Handle logout
    const handleLogout = (): void => {
        // Disconnect from WebSocket on logout
        if (import.meta.env.VITE_ENABLE_REAL_TIME_UPDATES === 'true') {
            realtimeService.disconnect();
        }
        // Call Auth0 logout
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    // Expose Auth0 functions through our context
    const contextValue: AuthContextValue = {
        ...state,
        dispatch,
        loginWithRedirect,
        loginWithGoogle: () => loginWithGoogle(loginWithRedirect),
        logoutWithRedirect: handleLogout,
        getToken: getAccessTokenSilently
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
}; 