import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';
import { InternalAxiosRequestConfig } from 'axios';

// Create users API instance
const usersApi = createApiInstance(apiEndpoints.users);

// Type for token getter function
type GetTokenFn = () => Promise<string>;

// User profile interface
interface UserProfile {
  id: string;
  email: string;
  name: string;
  avatar?: string;
  role: string;
  createdAt: string;
  lastLogin?: string;
}

// User preferences interface
interface UserPreferences {
  theme: 'light' | 'dark' | 'system';
  notifications: {
    email: boolean;
    inApp: boolean;
    marketing: boolean;
  };
  language: string;
  timezone: string;
}

// Configure auth interceptor in components
export const configureUsersApiWithAuth = (getToken: GetTokenFn): void => {
  usersApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    try {
      const token = await getToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (e) {
      console.error('Error getting token for user API request:', e);
    }
    return config;
  });
};

// User API services
export const userService = {
  // Get current user profile
  getProfile: async (): Promise<ApiResponse<UserProfile>> => {
    try {
      const response = await usersApi.get<ApiResponse<UserProfile>>('/me');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'User', 'get profile');
    }
  },

  // Update user profile
  updateProfile: async (profileData: Partial<UserProfile>): Promise<ApiResponse<UserProfile>> => {
    try {
      const response = await usersApi.patch<ApiResponse<UserProfile>>('/me', profileData);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'User', 'update profile');
    }
  },

  // Get user by ID (admin only)
  getById: async (userId: string): Promise<ApiResponse<UserProfile>> => {
    try {
      const response = await usersApi.get<ApiResponse<UserProfile>>(`/${userId}`);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'User', `get by ID ${userId}`);
    }
  },

  // Get user preferences
  getPreferences: async (): Promise<ApiResponse<UserPreferences>> => {
    try {
      const response = await usersApi.get<ApiResponse<UserPreferences>>('/me/preferences');
      return response.data;
    } catch (error) {
      return handleApiError(error, 'User', 'get preferences');
    }
  },

  // Update user preferences
  updatePreferences: async (preferences: Partial<UserPreferences>): Promise<ApiResponse<UserPreferences>> => {
    try {
      const response = await usersApi.patch<ApiResponse<UserPreferences>>('/me/preferences', preferences);
      return response.data;
    } catch (error) {
      return handleApiError(error, 'User', 'update preferences');
    }
  }
};

export default userService; 