import { createApiInstance, handleApiError } from './apiConfig';
import apiEndpoints from '../API_Endpoint';
import { ApiResponse } from '../types/api';

// Type definitions for WebSocket events and callbacks
type EventCallback = (data: any) => void;
type EventListeners = Record<string, EventCallback[]>;
type UnsubscribeFunc = () => void;

interface WebSocketMessage {
  type: string;
  data: any;
}

interface ConnectEventData {
  connected: boolean;
}

interface DisconnectEventData {
  code: number;
  reason: string;
}

interface ErrorEventData {
  error: Event;
}

// Singleton pattern for WebSocket connection management
let socket: WebSocket | null = null;
let reconnectTimer: number | null = null;
let eventListeners: EventListeners = {};
let authToken: string | null = null;

// Create API instance for initial authentication
const authApi = createApiInstance(apiEndpoints.auth);

// Real-time service for WebSocket communication
export const realtimeService = {
  // Initialize WebSocket connection
  connect: async (token: string): Promise<boolean> => {
    // Save token for reconnects
    authToken = token;
    
    // Only connect if real-time updates are enabled
    if (import.meta.env.VITE_ENABLE_REAL_TIME_UPDATES !== 'true') {
      console.log('Real-time updates are disabled');
      return false;
    }
    
    // Get WebSocket endpoint
    const wsEndpoint = import.meta.env.VITE_WS_ENDPOINT;
    if (!wsEndpoint) {
      console.error('WebSocket endpoint not configured');
      return false;
    }

    // Close existing connection if any
    if (socket) {
      socket.close();
    }

    try {
      // Create WebSocket connection with auth token
      socket = new WebSocket(`${wsEndpoint}?token=${token}`);
      
      // Setup event handlers
      socket.onopen = () => {
        console.log('WebSocket connection established');
        if (reconnectTimer !== null) {
          clearTimeout(reconnectTimer);
          reconnectTimer = null;
        }
        realtimeService.dispatchEvent('connect', { connected: true });
      };
      
      socket.onclose = (event: CloseEvent) => {
        console.log('WebSocket connection closed', event.code, event.reason);
        realtimeService.dispatchEvent('disconnect', { 
          code: event.code, 
          reason: event.reason 
        });
        
        // Auto-reconnect after delay
        reconnectTimer = window.setTimeout(() => {
          if (authToken) {
            realtimeService.connect(authToken);
          }
        }, 5000);
      };
      
      socket.onerror = (error: Event) => {
        console.error('WebSocket error:', error);
        realtimeService.dispatchEvent('error', { error });
      };
      
      socket.onmessage = (event: MessageEvent) => {
        try {
          const message: WebSocketMessage = JSON.parse(event.data);
          realtimeService.dispatchEvent(message.type, message.data);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
      
      return true;
    } catch (error) {
      console.error('Error establishing WebSocket connection:', error);
      return false;
    }
  },
  
  // Disconnect WebSocket
  disconnect: (): boolean => {
    if (socket) {
      socket.close();
      socket = null;
    }
    
    if (reconnectTimer !== null) {
      clearTimeout(reconnectTimer);
      reconnectTimer = null;
    }
    authToken = null;
    return true;
  },
  
  // Send message through WebSocket
  send: (type: string, data: any): boolean => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      console.error('WebSocket not connected');
      return false;
    }
    
    try {
      socket.send(JSON.stringify({ type, data }));
      return true;
    } catch (error) {
      console.error('Error sending WebSocket message:', error);
      return false;
    }
  },
  
  // Subscribe to events
  on: (event: string, callback: EventCallback): UnsubscribeFunc => {
    if (!eventListeners[event]) {
      eventListeners[event] = [];
    }
    
    eventListeners[event].push(callback);
    return () => realtimeService.off(event, callback);
  },
  
  // Unsubscribe from events
  off: (event: string, callback: EventCallback): void => {
    if (!eventListeners[event]) return;
    
    eventListeners[event] = eventListeners[event].filter(cb => cb !== callback);
  },
  
  // Dispatch event to all listeners
  dispatchEvent: (event: string, data: any): void => {
    if (!eventListeners[event]) return;
    
    eventListeners[event].forEach(callback => {
      try {
        callback(data);
      } catch (error) {
        console.error('Error in event listener:', error);
      }
    });
  },
  
  // Get current connection status
  isConnected: (): boolean => {
    return socket !== null && socket.readyState === WebSocket.OPEN;
  },
  
  // Register for notifications (server-side configuration)
  registerForNotifications: async (token: string, channels: string[] = ['user']): Promise<ApiResponse<{ success: boolean }>> => {
    try {
      // Configure token for authorization
      authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Register for notifications on the specified channels
      const response = await authApi.post<ApiResponse<{ success: boolean }>>('/notifications/register', { channels });
      return response.data;
    } catch (error) {
      return handleApiError(error, 'Realtime', 'register for notifications');
    }
  }
};

export default realtimeService; 