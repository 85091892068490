import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

interface LoaderProps {
  size?: number;
  color?: string;
  text?: string;
  fullScreen?: boolean;
  className?: string;
}

/**
 * Loader component displays a loading spinner with optional text
 */
const Loader: React.FC<LoaderProps> = ({
  size = 50,
  color = '#0096FF',
  text = 'Loading...',
  fullScreen = false,
  className = '',
}) => {
  const containerClasses = fullScreen
    ? 'fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50'
    : `flex flex-col items-center justify-center p-4 ${className}`;

  return (
    <div className={containerClasses} data-testid="loader">
      <RotatingLines
        strokeColor={color}
        strokeWidth="5"
        animationDuration="0.75"
        width={size.toString()}
        visible={true}
      />
      {text && <p className="mt-4 text-gray-600 font-medium">{text}</p>}
    </div>
  );
};

export default Loader; 