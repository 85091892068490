import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { projectService } from '../API/apiServices';
import Loader from '../../../Components/Loader';
import { Project } from '../../../types';

interface ProjectTemplate {
  id: string;
  name: string;
  icon: string;
}

const ProjectEdit: React.FC = () => {
    const { projectID } = useParams<{ projectID: string }>();
    const navigate = useNavigate();
    const [projectData, setProjectData] = useState<Project | null>(null);
    const [projectName, setProjectName] = useState<string>('');
    const [projectDescription, setProjectDescription] = useState<string>('');
    const [selectedTemplate, setSelectedTemplate] = useState<string>('custom');
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Project template options
    const projectTemplates: ProjectTemplate[] = [
        { id: 'web', name: 'Web Development', icon: '🌐' },
        { id: 'data', name: 'Data Analysis', icon: '📊' },
        { id: 'content', name: 'Content Creation', icon: '✍️' },
        { id: 'code', name: 'Code Assistant', icon: '💻' },
        { id: 'custom', name: 'Custom', icon: '🔍' }
    ];

    // Fetch project data on component mount
    useEffect(() => {
        fetchProject();
    }, [projectID]);

    const fetchProject = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await projectService.getById(projectID as string);
            if (response.success && response.project) {
                const project = response.project;
                setProjectData(project);
                setProjectName(project.name);
                setProjectDescription(project.description || '');
                setSelectedTemplate(project.template);
                setError(null);
            } else {
                setError('Failed to fetch project details');
            }
        } catch (error: any) {
            console.error('Error fetching project:', error);
            setError(error.message || 'An error occurred while fetching the project');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        try {
            setSaving(true);
            setError(null);

            // Basic validation
            if (!projectName.trim()) {
                setError('Project name is required');
                setSaving(false);
                return;
            }

            const updatedProject = {
                name: projectName,
                description: projectDescription,
                template: selectedTemplate
            };

            const response = await projectService.update(projectID as string, updatedProject);

            if (response.success) {
                navigate(`/dashboard/project/${projectID}`);
            } else {
                setError('Failed to update project');
            }
        } catch (error: any) {
            console.error('Error updating project:', error);
            setError(error.message || 'An error occurred while updating the project');
        } finally {
            setSaving(false);
        }
    };

    const handleCancel = (): void => {
        navigate(`/dashboard/project/${projectID}`);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
                <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Edit Project</h1>
                
                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                        {error}
                    </div>
                )}
                
                <form onSubmit={handleSave}>
                    <div className="mb-6">
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                            Project Name
                        </label>
                        <input
                            type="text"
                            value={projectName}
                            onChange={(e) => setProjectName(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter project name"
                        />
                    </div>
                    
                    <div className="mb-6">
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                            Description
                        </label>
                        <textarea
                            value={projectDescription}
                            onChange={(e) => setProjectDescription(e.target.value)}
                            rows={4}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter project description"
                        />
                    </div>
                    
                    <div className="mb-6">
                        <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                            Project Template
                        </label>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
                            {projectTemplates.map(template => (
                                <div
                                    key={template.id}
                                    onClick={() => setSelectedTemplate(template.id)}
                                    className={`cursor-pointer border rounded-lg p-4 transition ${
                                        selectedTemplate === template.id
                                            ? 'border-blue-500 bg-blue-50 dark:bg-blue-900 dark:border-blue-400'
                                            : 'border-gray-300 hover:border-blue-400 dark:border-gray-600 dark:hover:border-blue-500'
                                    }`}
                                >
                                    <div className="text-3xl mb-2">{template.icon}</div>
                                    <div className="font-medium text-gray-900 dark:text-white">{template.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={saving}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50"
                        >
                            {saving ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProjectEdit; 